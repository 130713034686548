import {backendURL} from '../environment';

let API_URL = backendURL + 'api/';

export default class NavElementService {
    static myInstance = null;

    static getInstance() {
        if (NavElementService.myInstance == null) {
            NavElementService.myInstance = new NavElementService();
        }
        return this.myInstance
    }

    findAllNavElements = () =>
        fetch(API_URL + "navs", {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

    findNavElementById = (id) =>
        fetch(API_URL + 'navs/' + id)
            .then(response => response.json());

    createNavElement = (token, navElement) => {
        return fetch(API_URL + "navs", {
            method: 'post',
            body: JSON.stringify(navElement),
            headers: {
                'content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json())
            .catch(() => alert("Nav Element Not Be Created"));
    };

    updateNavElement = (token, navId, navElement) => {
        return fetch(API_URL + "navs/" + navId, {
            method: 'put',
            body: JSON.stringify(navElement),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    deleteNavElement = (token, navId) => {
        return fetch(API_URL + "navs/" + navId, {
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };


}
