<template>
  <div class="login">
      <h2 class="loginHeader">Please Login</h2>
      <div class="email d-inline-flex">
        <h3 class="enter">Enter Email</h3>
        <input type="email" class="form-control" @change="changeEmail"/>
      </div>
      <div class="password d-inline-flex">
        <h3 class="enter">Password</h3>
        <input type="password" class="form-control" @change="changePassword"/>
      </div>
      <div class="loginButtons">
        <b-button type="submit" variant="primary" class="loginButton" @click="onSubmit">Login</b-button>
        <b-button type="button" variant="success" class="registerButton" @click.stop="register()" >Application</b-button>
      </div>
      <router-link class="forgotPassword" to="forgotPassword">Forgot Password?</router-link>
  </div>
</template>

<script>

import axios from 'axios'
import {mapActions} from "vuex";
import {backendURL} from '../../environment';

export default {
  name: 'LoginComponent',
  data () {
    return {
      login: {
        user: {
        }
      },
      errors: []
    }
  },
  methods: {
    ...mapActions([
        'addUser'
    ]),
    changeEmail(e) {
      this.login.user.email = e.target.value
    },
    changePassword(e) {
      this.login.user.password = e.target.value
    },
    onSubmit (evt) {
      evt.preventDefault();
      axios.post(backendURL + `api/users/login`, this.login)
        .then(response => {
          let payload = {
            token: response.data.user.token
          };
          this.addUser(payload);
          this.$router.push('/')
        })
        .catch(e => {
          this.errors.push(e)
          alert("Wrong username/password combination")
        })
    },
    register () {
      this.$router.push({
        name: 'Register'
      })
    }
  }
}
</script>

<style scoped>
  .loginButtons{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .bootstrapForm {
    width: 100%;
  }
  .d-inline-flex {
    text-align: left;
    width: 100%;
    margin-top: 5%;
  }
  .form-control {
    width: 65%;
    margin-left: 5%;
  }
  .enter {
    width: 30%;
  }
  .forgotPassword {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 3%;
  }
  .loginHeader {
    font-size: 45px;
    color: red;
    font-weight: bolder;
    font-style: italic;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .login {
    margin-top: 10%;
    width: 80%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .registerButton {
    width: 65%;
    margin-left: 10%;
    margin-top: 2%;
  }
  .loginButton {
    width: 65%;
    margin-top: 3%;
    margin-left: 10%;
  }
  @media (min-width: 700px) {
    .login {
      width: 50%;
    }

  }

</style>
