<template>
    <div class="cardEditorContainer" :key="type">
        <h1 class="editorHeader">New Card</h1>
        <div  class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Card Type: {{type}}</h3>
            <div class="editorContentContainer">
                <select @change="changeCardType"
                        class="inputBox form-control">
                    <option style="display: none" :value="type">{{type}}</option>
                    <option value="COACH">Coach</option>
                    <option value="ATHLETE">Athlete</option>
                    <option value="ARTICLE">Article</option>
                </select>
            </div>
        </div>
        <div class="editorContentContainer border border-dark">
            <h3  class="editorContentHeader">Name of Card</h3>
            <div class="inputBox">
                <input class="form-control"
                       :value="currentCardTitle"
                       @change="cardNameChange"/>
            </div>
        </div>
        <div v-if="type === 'ATHLETE'">
            <div class="editorContentContainer border border-dark">
                <h3 class="editorContentHeader">Event Groups</h3>
                <div v-for="event in currentEventGroup" :key="event" class="groupOfCards btn-group">
                    <input type="text" class="cardsForPage form-control" :value="event" readonly/>
                    <span @click="removeEvent(event)" class="cardClear fa fa-times">
                        <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                    </span>
                </div>
                <select @change="selectEvent" class="inputBox form-control">
                    <option style="display: none" selected="main">---</option>
                    <option v-for="event in eventGroup" :key="event" :value="event">{{event}}</option>
                </select>
                <button @click="addEvent" class="addDropdown btn btn-block">Add Card</button>
            </div>
            <div class="editorContentContainer border border-dark">
                <h3 class="editorContentHeader">Gender: {{gender}}</h3>
                <select @change="changeGender" class="inputBox form-control">
                    <option style="display: none" selected="main">{{gender}}</option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHER">Other</option>
                </select>
            </div>
            <div class="editorContentContainer border border-dark">
                <h3 class="editorContentHeader">Division: {{ageGroup}}</h3>
                <select @change="changeDivision" class="inputBox form-control">
                    <option style="display: none" selected="main">{{ageGroup}}</option>
                    <option value="OPEN">OPEN</option>
                    <option value="MASTERS">MASTERS</option>
                </select>
            </div>
        </div>
        <TextAreaEditor heading="Card Content: " :parent-method="changeCardContent" :value="currentCardContent"/>
        <ImageUpload :image="img" :parent-file-change="fileChange"/>
        <button @click="createCard" class="saveState btn btn-block btn-success">
            Create Card</button>
    </div>
</template>

<script>
    import CardService from "../../../services/CardService";
    import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
    import axios from "axios";
    import ImageUpload from "../../../assets/ImageUpload";
    import TextAreaEditor from "../../../assets/TextAreaEditor";
    import {backendURL} from '../../../environment'


    export default {
        name: "CardEditor",
        components: {TextAreaEditor, ImageUpload},
        data() {
            return {
                gender: "",
                ageGroup: "",
                type: "",
                currentCardTitle: "",
                currentCardContent: "",
                currentCardImage: "",
                img: "",
                author: "",
                currentEventGroup: [],
                eventGroup: ["MARATHON", "ROAD RUNNER", "XC", "TRACK", "FIELD"]

            }
        },
        computed: {
            timesIcon() {
                return faTimes
            }
        },
        methods: {
            fileChange(e) {
                if(window.confirm("Are you sure you want to upload this image?")) {
                    const allowedTypes = ["image/jpeg", "image,jpg", "image/png"];
                    var files = e.target.files || e.dataTransfer.files;
                    const file = files[0];
                    if (!allowedTypes.includes(file.type)) {
                        alert("Wrong Type!");
                        return;
                    }
                    if (file.size > 500000) {
                        alert("File too large!");
                        return;
                    }
                    let formData = new FormData();
                    formData.append('file', file);
                    axios.post(backendURL + 'api/cards/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(responso => {
                        this.img = (responso.data.location);
                    }).catch(() => {
                        alert("Something went wrong uploading the image, please try again.")
                    })
                }
            },
            createCard() {
                let card = {
                    name: this.currentCardTitle,
                    type: this.type,
                    eventGroup: this.currentEventGroup,
                    content: this.currentCardContent,
                    gender: this.gender,
                    ageGroup: this.ageGroup,
                    author: this.author,
                    img: this.img
                };
                if(this.currentCardTitle === '' || this.type === '') {
                    alert("Card title and type must be filled out!")
                } else if(window.confirm("Are you sure you want to create this card?")) {
                    this.cardService.createCard(this.$cookies.get('user'), card).then((card) => {
                        alert(card.name + "'s card created successfully!")
                        this.currentCardTitle = "";
                        this.currentEventGroup = [];
                        this.currentCardContent = "";
                        this.gender = "";
                        this.ageGroup = "";
                        this.author = "";
                        this.img = "";
                        this.type = ""
                    })
                }
            },
            changeCardType(event) {
                this.type = event.target.value
            },
            cardNameChange(event) {
                this.currentCardTitle = event.target.value
            },
            changeCardContent(event) {
                this.currentCardContent = event.target.value
            },
            removeEvent(event) {
                this.currentEventGroup = this.currentEventGroup
                    .filter(e => e !== event);
            },
            selectEvent(event) {
                this.currentEvent = event.target.value
            },
            addEvent() {
                let eventAdded = false;
                this.currentEventGroup.forEach(e => {
                    if(this.currentEvent === e) {
                        eventAdded = true
                    }
                });
                if(eventAdded) {
                    alert("Event already added!")
                } else {
                    this.currentEventGroup.push(this.currentEvent)
                }
            },
            changeGender(event) {
                this.gender = event.target.value
            },
            changeDivision(event) {
                this.ageGroup = event.target.value
            },
        },
        mounted() {
            this.cardService = CardService.getInstance();
        },
    }
</script>

<style scoped>
    .saveState {
        margin-bottom: 2%;
    }
    .cardClear {
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        height: 14px;
        margin: auto;
        font-size: 14px;
        cursor: pointer;
        color: #ccc;
    }
    .groupOfCards {
        margin: 1%;
    }
    .inputBox {
        width: 80%;
    }
    .addDropdown {
        background-color: navy;
        color: white;
        margin: 1%;
        width: 90%;
    }

</style>
