<template>
    <div class="memberContainer">
        <h3 class="memberHeading">{{user.name}}</h3>
        <PlainTextEditor type="Date" heading="Membership Duration" :val="user.membershipDuration" :parent-method="changeDate"/>
        <button class="btn btn-success" @click="saveDate">Change Membership Duration</button>
    </div>
</template>

<script>
    import MemberService from "../../../services/memberService";
    import Vue from "vue";
    import PlainTextEditor from "../../../assets/PlainTextEditor";

    export default {
        name: "MemberComponent",
        components: {PlainTextEditor},
        data() {
            return {
                user: {}
            }
        },
        methods: {
            changeMember(member) {
                this.user = member;
                let myDate = new Date(member.membershipDuration);
                let myDay = myDate.getUTCDate();
                let myMonth = myDate.getMonth();
                let myYear = myDate.getFullYear().toString();
                if(parseInt(myYear) < 10) {
                    myYear = '0' + myYear
                }
                if(parseInt(myYear) < 100) {
                    myYear = '0' + myYear
                }
                if(parseInt(myYear) < 1000) {
                    myYear = '2' + myYear
                }
                if(myDay < 10) {
                    myDay = '0' + myDay
                }
                if(myMonth++ < 10) {
                    myMonth = '0' + (myMonth++)
                }
                this.user.membershipDuration = '' + myYear + '-' + myMonth + '-' + myDay
            },
            changeDate(event) {
                this.user.membershipDuration = event.target.value
            },
            saveDate() {
                if(window.confirm("Are you sure you want to update member's membership duration?")) {
                    this.memberService.renewMembership(Vue.$cookies.get('user'), this.user).then(() => {
                        alert("Success");
                        this.$router.push("/members/members")
                    });
                }
            }
        },
        mounted() {
            this.memberService = MemberService.getInstance();
            this.memberService.findMemberByName(Vue.$cookies.get('user'), this.$route.params.name).then((user) => {
                this.changeMember(user[0])
            })
        }
    }
</script>

<style scoped>
    .memberHeading {
        color: red;
    }

</style>