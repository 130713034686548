<template>
  <div class="registerContainer">
    <h2 class="applicationHeader">Application</h2>
    <div><span class="required-class"> * </span>Please fill out all of the provided fields:</div>
    <StandardForm :parent-method="changeName" heading="First and Last Name"/>
    <StandardForm :parent-method="changeEmail" heading="Enter Email"/>
    <DropdownForm :parent-method="changeGender" heading="Gender" :elements="genders" :multiple="false"/>
    <DropdownForm :parent-method="changeAgeGroup" heading="Age Group" :elements="ageGroups" :multiple="false"/>
    <StandardForm :parent-method="changePreviousClub" heading="Previous Club/College"/>
    <StandardForm :parent-method="changeHeardAbout" heading="How did you hear about us?"/>
    <StandardForm :parent-method="changePassword" type="password" heading="Enter Password"/>
    <StandardForm :parent-method="checkPassword" type="password" heading="Confirm Password"/>

    <div class="eventContainer editorContentContainer border border-dark">
      <h3 class="editorContentHeader">Events</h3>
      <div class="btn-group" v-for="event in user.events" :key="event">
        <input type="text" class="form-control" :value="event" readonly/>
        <span @click="deleteEvent(event)" class="cardClear fa fa-times">
          <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon"/>
        </span>
      </div>
      <select @change="selectEvent" class="inputBox form-control">
        <option style="display: none" selected="main">---</option>
        <option v-for="event in events" :key="event" :value="event">{{event.charAt(0) + event.slice(1).toLowerCase()}}</option>
      </select>
      <button @click="addEvent" class="addDropdown btn btn-block">Add Dropdown Page</button>
    </div>
    <div class="textAreaContainer border border-dark">
      <h3>Tell Us About Yourself:</h3>
      <textarea @change="changeBio" class="textArea" name="message"></textarea>
    </div>
    <button type="submit" @click="onSubmit" class="btn btn-success registerButton">Apply</button>
  </div>
</template>

<script>

  import axios from 'axios'
  import StandardForm from "../../assets/StandardForm";
  import DropdownForm from "../../assets/DropdownForm";
  import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
  import {backendURL} from '../../environment';

  export default {
    name: 'RegisterComponent',
    components: {DropdownForm, StandardForm},
    data () {
      return {
        user: {
          events: [],
          bio: ""
        },
        errors: [],
        event: "",
        passwordMatch: false,
        filledOut: false,
        genders: ["MALE", "FEMALE", "NONBINARY"],
        ageGroups: ["OPEN", "MASTERS"],
        events: ["", "MARATHON", "ROAD RUNNER", "XC", "TRACK", "FIELD"]
      }
    },
    computed: {
      timesIcon(){
        return faTimes
      },
    },
    methods: {
      changePreviousClub() {
        this.user.previousClub = event.target.value
      },
      changeHeardAbout() {
        this.user.heardAbout = event.target.value
      },
      onSubmit () {
        if(this.passwordMatch) {
          if(this.filledOut) {
            axios.post(backendURL + `api/users`, this.user)
                    .then(() => {
                      alert('Registered successfully');
                      this.$router.push({
                        name: 'Login'
                      })
                    })
                    .catch(() => {
                      alert("Name or email already in use")
                    })
          } else {
            alert("Not Filled Out!")
          }
        } else {
          alert("Passwords don't match!")
        }
      },
      addEvent() {
        let added = false;
        this.user.events.forEach(event => {
          if(event === this.event) {
            added = true
          }
        });
        if(added) {
          alert("Already Added")
        } else {
          this.user.events.push(this.event)
        }
        this.checkFill()
      },
      selectEvent() {
        this.event = event.target.value
      },
      deleteEvent() {
        let i =0;
        this.user.events.forEach(event => {
          if(event === this.event) {
            this.user.events.splice(i, 1)
          }
          i++
        });
        this.checkFill()
      },
      changeGender(event) {
        this.user.gender = event.target.value;
        this.checkFill()
      },
      changeName(event) {
        this.user.name = event.target.value;
        this.checkFill()
      },
      changeEmail(event) {
        this.user.email = event.target.value
        this.checkFill()
      },
      changeAgeGroup(event) {
        this.user.ageGroup = event.target.value
        this.checkFill()
      },
      changeBio(event) {
        this.user.bio = event.target.value
        this.checkFill()
      },
      check(password) {
        return this.user.password === password;
      },
      checkFill() {
        this.filledOut = !!(this.user.name && this.user.email && this.user.password && this.user.bio !== "" && this.user.ageGroup
                && this.user.gender && this.user.heardAbout && this.user.previousClub && this.user.events.length !== 0);
      },
      changePassword(event) {
        this.user.password = event.target.value;
        this.passwordMatch = this.check(this.user.password === event.target.value);
        this.checkFill()
      },
      checkPassword(event) {
        this.passwordMatch = this.check(event.target.value);
      }
    }
  }
</script>

<style scoped>
  .required-class {
    color: red;
  }
  .applicationHeader {
    font-size: 50px;
    color: red;
    font-weight: bolder;
    font-style: italic;
  }
  .eventContainer {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  .textAreaContainer {
    margin-left: auto;
    margin-top: 2%;
    margin-right: auto;
    width: 60%;
    height: 200px;
  }
  .textArea {
    width: 100%;
    height: 80%;
  }
  .addDropdown {
    background-color: navy;
    color: white;
    margin: auto auto 1%;
    width: 90%;
  }
  .form-control {
    margin-left: 2%;
  }
  .inputBox {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .cardClear {
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    height: 14px;
    margin-left: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
  }

  .registerContainer {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .registerButton {
    width: 60%;
    margin-top: 2%;
  }

</style>
