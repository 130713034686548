<template>
    <div class="pageBody">
        <div v-if="pageType === 'ARTICLE'"><ArticleComponent :articleTitle="pageName"
                                                    :richText="page.richText"
                                                    :articleImg="page.img"/></div>
        <div v-else-if="pageType === 'COACH'"><Coaches :page="page"/></div>
        <div v-else-if="pageType === 'ATHLETE'"><Athletes :page="page"/></div>
        <div v-else-if="pageType === 'CARDS'"><Cards/></div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import PageService from "../../services/PageService";
    import ArticleComponent from "./Article";
    import Coaches from "./Coaches";
    import Athletes from "./Athletes";
    import Cards from "./Cards";
    export default {
        name: "PageComponent",
        components: {Cards, Athletes, Coaches, ArticleComponent},
        computed: {
            ...mapGetters([
                'getPage'
            ])
        },
        data() {
            return{
                pageName: '',
                pageType: '',
                page: {}
            }
        },
        methods: {
        },
        mounted() {
            this.pageService = PageService.getInstance()
            this.pageService.findPageByName(this.$route.params.name).then(page => {
                this.pageName = page.name;
                this.pageType = page.type;
                this.page = page;
            });
        }
    }
</script>

<style scoped>
    .pageBody {
        text-align: center;
    }
    .pageName {
        text-align: center;
    }
</style>
