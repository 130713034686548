<template>
    <div>
        <h1 class="editorHeader">
            GBTC Race Registration
        </h1>
        <h1 id="comingSoon" class="editorHeader">
            COMING SOON
        </h1>`
    </div>
</template>

<script>
    export default {
        name: "GbtcRaceRegistration"
    }
</script>

<style scoped>
    #comingSoon {
        color: #f1f1f1;
    }
</style>