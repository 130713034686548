<template>
    <div class="eventConfirmationContainer">
        <h1>{{eventName}} Confirmation</h1>
        <b-card class="eventInfoContainer">
            <b-card-text>
                <h2>Is this information Correct?</h2>
                <b-row class="text-center">
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">First Name:</b> {{registrant.firstName}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">Last Name:</b> {{registrant.lastName}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">Email:</b> {{registrant.email}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">Division:</b> {{registrant.division}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">DOB:</b> {{registrant.dob}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">Phone:</b> {{registrant.phone}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">Country:</b> {{registrant.country}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">Zip:</b> {{registrant.zip}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">City:</b> {{registrant.city}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">State:</b> {{registrant.state}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">Emergency Contact:</b> {{registrant.emergencyContact}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">Relationship:</b> {{registrant.relationship}}</b-col>
                    <b-col cols="12" md="auto" class="registrantInfo"><b class="field">Their Phone:</b> {{registrant.theirPhone}}</b-col>
                    <b-col v-if="registrant.usatfNumber" cols="12" md="auto" class="registrantInfo"><b class="field">USATF Number:</b> {{registrant.usatfNumber}}</b-col>
                    <b-col v-if="registrant.teeShirtSize" cols="12" md="auto" class="registrantInfo"><b class="field">Tee Shirt Size:</b> {{registrant.teeShirtSize}}</b-col>
                </b-row>
            </b-card-text>
            <b-card-text class="border-top border-dark">
                    <h2 class="field">Event(s):</h2>
                <div v-for="race in registrant.event" :key="race.name" class="eventContainer">
                    <h4>{{race.name}}</h4>
                    <b-col cols="12" md="auto" class="eventInfo">
                        Race: {{race.distance}}
                    </b-col>
                    <b-col cols="12" md="auto" class="eventInfo" v-if="event.onePayment !== 'TRUE'">
                       Price: {{race.price}}$
                    </b-col>
                    <b-col cols="12" md="auto" class="eventInfo">
                        Time: {{race.time}}
                    </b-col>
                </div>
            </b-card-text>

            <b-card-text class="border-top border-bottom border-dark" v-if="registrant.clothing.length > 0">
                <h2 class="field">Clothing Item(s): </h2>
                <div v-for="item in registrant.clothing" :key="item.name" class="eventContainer">
                    <h4>{{item.name}}</h4>
                    <b-col cols="12" md="auto" class="eventInfo">
                        Price {{item.price}}
                    </b-col>
                    <b-col cols="12" md="auto" class="eventInfo">
                        <img class="clothingImage" :src="item.img"/>
                    </b-col>
                </div>
            </b-card-text>

            <button class="btn-primary" @click="correct">This information is correct</button>
            <a class="card-link"> <router-link :to="`../registration/${eventName}`">Edit Info</router-link></a>
        </b-card>
        <div v-if="renderPay" class="eventPaymentContainer">
            <Payment :credentials="credentials" :payment="totalDue" :cancelled="cancelled" :authorized="authorized"></Payment>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import ApiService from "../../services/apiService";
    import Payment from "../../assets/Payment";
    import EventRegistrationService from "../../services/EventRegistrationService";
    export default {
        name: "EventPaymentPage",
        components: {Payment},
        computed: {
            ...mapGetters([
                'getRegistrant'
            ]),
            renderPay() {
                return this.isMounted && this.correctInfo
            },
            totalDue() {
                let total = 0;
                if(this.event.onePayment !== "TRUE") {
                    this.registrant.event.forEach(e => {
                        total += parseFloat(e.price)
                    })
                } else {
                    total += this.event.onePaymentPrice
                }
                this.registrant.clothing.forEach(item => {
                    total += parseFloat(item.price)
                });
                return total.toString()
            }
        },
        data() {
            return {
                eventName: "",
                isMounted: false,
                event: {},
                correctInfo: false,
                credentials : {},
                payment: "5",
                registrant: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    division: "",
                    dob: "",
                    phone: "",
                    country: "",
                    zip: "",
                    city: "",
                    state: "",
                    emergencyContact: "",
                    relationship: "",
                    theirPhone: "",
                    usatfNumber: "",
                    teeShirtSize: "",
                    team: "",
                    event: {},
                    clothing: []
                },

            }
        },
        methods: {
            checkStatus() {
                if(this.registrant.firstName && this.registrant.lastName && this.registrant.email
                    && this.registrant.division && this.registrant.dob && this.registrant.phone
                    && this.registrant.country && this.registrant.zip && this.registrant.city && this.registrant.state
                    && this.registrant.emergencyContact && this.registrant.relationship
                    && this.registrant.theirPhone) {
                    return true
                }
            },
            correct() {
                this.correctInfo = true;
            },
            authorized() {
                this.eventService.register(this.registrant, this.event._id).then(() => {
                    this.$router.push({path: "../confirmation/" + this.$route.params.name})
                }).catch(() => {
                    alert("Something went wrong, your payment HAS been processed, please contact a GBTC representative")
                })
            },
            cancelled() {
                alert("Transaction Cancelled")
            }
        },
        mounted() {
            this.registrant = this.getRegistrant;
            if(!this.checkStatus()) {
                this.$router.push({path: "../registration/" + this.$route.params.name})
            }
            this.eventName = this.$route.params.name;
            let apiService = ApiService.getInstance();
            apiService.findPPKey().then(ppKey => this.credentials = ppKey);

            this.eventService = EventRegistrationService.getInstance();
            this.eventService.findEventByName(this.$route.params.name).then(event => {
                this.event = event;
            });


            this.isMounted = true;
        }
    }
</script>

<style scoped>
    .clothingImage {
        width: 100px;
        height: 100px;
    }
    .eventConfirmationContainer {
        text-align: center;
    }
    .text-center {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        margin-top: 1%;
        font-size: 12px;
     }
    .registrantInfo {
        width: 33%;
    }
    .eventInfoContainer {
        margin-top: 2%;
    }
    .eventInfo {
        width: 33%;
        font-size: 12px;
        display: inline;
    }
    .card-link {
        margin-left: 10%;
    }
    .eventContainer {
        margin-top: 2%;
    }
    .eventPaymentContainer{
        margin-top: 2%;
    }
</style>
