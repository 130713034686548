<template>
    <div>
        <h1 id="pageHeader" class="editorHeader">Page Editor</h1>
        <h4 class="inputBox">Filter Pages By:</h4>
        <select @change="filterPages" id="filterPages"
            class="inputBox form-control">
            <option style="display: none" :value="this.status">{{this.status}}</option>
            <option>PUBLISHED</option>
            <option>DRAFT</option>
        </select>
        <h4 class="inputBox">Page:</h4>
        <select @change="changePage($event)" id="filterPages"
            class="inputBox form-control">
            <option style="display: none" :value="page.name">{{page.name}}</option>
            <option v-for="page in pages" :key="page._id" @click="changePage(page)">{{page.name}}</option>
        </select>
        <PlainTextEditor heading="Name of Page" :val="pageName" :parentMethod="pageNameChange"/>
        <PlainTextEditor heading="Author of Page" :val="author" :parentMethod="authorChange"/>
        <div v-if="page.type === 'CARDS'" id="pageCards" class="editorContentContainer border border-dark">
            <h3 id="pageCardHeader" class="editorContentHeader">Page's Cards</h3>
            <div v-for="card in cards" :key="card._id" class="groupOfCards btn-group">
                <input type="text" class="cardsForPage form-control" :value="card.name" readonly/>
                <span @click="deleteCard(card)" class="cardClear fa fa-times">
                    <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                </span>
            </div>
            <select @change="selectCard" id="selectCard" class="inputBox form-control">
                <option style="display: none" selected="main">---</option>
                <option v-for="card in contentCards" :key="card._id" :value="card.name">{{card.name}}</option>
            </select>
            <button @click="addCard" class="addDropdown btn btn-block">Add Card</button>
        </div>
        <div v-if="body && page.type === 'ARTICLE'" :key="page._id">
                <div class="editorContentContainer border border-dark">
                    <h3 class="editorContentHeader">Page Content</h3>
                    <div class="richTextEditorContainer">
                        <RichTextEditor :richText="body" :changeRichText="changeRichText"/>
                    </div>
                </div>
            <ImageUpload :image="img" :parent-file-change="fileChange"/>
        </div>
        <div v-if="page.type === 'ATHLETE'" class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Page's Cards</h3>
            <div v-for="card in cards" :key="card._id" class="groupOfCards btn-group">
                <input type="text" class="cardsForPage form-control" :value="card.name" readonly/>
                <span @click="deleteCard(card)" class="cardClear fa fa-times">
                    <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                </span>
            </div>
            <select @change="selectCard" class="inputBox form-control">
                <option style="display: none" selected="main">---</option>
                <option v-for="card in athleteCards" :key="card._id" :value="card.name">{{card.name}}</option>
            </select>
            <button @click="addCard" class="addDropdown btn btn-block">Add Card</button>
        </div>
        <div v-if="page.type === 'COACH'" class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Page's Cards</h3>
            <div v-for="card in cards" :key="card._id" class="groupOfCards btn-group">
                <input type="text" class="cardsForPage form-control" :value="card.name" readonly/>
                <span @click="deleteCard(card)" class="cardClear fa fa-times">
                    <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                </span>
            </div>
            <select @change="selectCard" class="inputBox form-control">
                <option style="display: none" selected="main">---</option>
                <option v-for="card in coachCards" :key="card._id" :value="card.name">{{card.name}}</option>
            </select>
            <button @click="addCard" class="addDropdown btn btn-block">Add Card</button>
        </div>
        <div  class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Page Status: {{status}}</h3>
            <div class="editorContentContainer">
                <select @change="changeStatus"
                    class="inputBox form-control">
                    <option style="display: none" :value="status">{{status}}</option>
                    <option>PUBLISHED</option>
                    <option>DRAFT</option>
                </select>
            </div>
        </div>
        <button @click="savePage" class="saveState btn btn-block btn-success">Save Page</button>
        <button @click="deletePage" class="deleteButton btn btn-block btn-danger">Delete Page</button>
    </div>

</template>

<script>
    import PageService from "../../../services/PageService";
    import CardService from "../../../services/CardService";
    import PlainTextEditor from "../../../assets/PlainTextEditor";
    import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
    import RichTextEditor from "../../../assets/RichTextEditor";
    import ImageUpload from "../../../assets/ImageUpload";
    import {backendURL} from "../../../environment";
    import axios from "axios";

    export default {
        name: "PageEditor",
        components: {ImageUpload, RichTextEditor, PlainTextEditor},
        data() {
            return {
                pages: [],
                cards: [],
                contentCards: [],
                coachCards: [],
                athleteCards: [],
                allCards: [],
                type: "",
                status: "",
                pageName: "",
                author: "",
                body: "",
                card: {},
                page: {},
                img: "",
                allPages: []
            }
        },
        computed: {
            timesIcon(){
                return faTimes
            },
            richText(){
                return this.body
            }
        },
        methods: {
            fileChange(e) {
                if(window.confirm("Are you sure you want to upload this image?")) {
                    const allowedTypes = ["image/jpeg", "image,jpg", "image/png"];
                    var files = e.target.files || e.dataTransfer.files;
                    const file = files[0];
                    if (!allowedTypes.includes(file.type)) {
                        alert("Wrong Type!");
                        return;
                    }
                    if (file.size > 500000) {
                        alert("File too large!");
                        return;
                    }
                    let formData = new FormData();
                    formData.append('file', file);
                    axios.post(backendURL + 'api/pages/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(responso => {
                        this.img = (responso.data.location);
                    }).catch(() => {
                        alert("Something went wrong uploading the image, please try again")
                    })
                }
            },
            filterPages(event) {
                let tempPageList = [];
                let status = "";
                if (event === "") {
                    status = "PUBLISHED";
                } else {
                    status = event.target.value;
                }
                this.allPages.map(page => {
                    if (page.status === status) {
                        tempPageList.push(page)
                    }
                });
                if (tempPageList.length > 0) {
                    this.pages = tempPageList;
                    this.page = tempPageList[0];
                    this.paragraphs = tempPageList[0].paragraphs;
                    this.pageName = tempPageList[0].name;
                    this.status = tempPageList[0].status;
                    this.img = tempPageList[0].img;
                    this.author = tempPageList[0].author;
                    this.body = tempPageList[0].richText;
                    this.cards = tempPageList[0].cards;
                } else {
                    this.pages = [];
                    this.page = {};
                    this.pageName = "";
                    this.author = ""
                }
            },
            pageNameChange(event) {
                this.pageName = event.target.value
            },
            authorChange(event) {
                this.author = event.target.value
            },
            changePage(event) {
                this.pageService.findPageByName(event.target.value).then(page => {
                    this.page = page;
                    this.pageName = page.name;
                    this.paragraphs = page.paragraphs;
                    this.cards = page.cards;
                    this.author = page.author;
                    this.status = page.status;
                    this.img = page.img;
                    this.body = page.richText
                }).then(() => {
                    if(this.page.type === 'ATHLETE') {
                        this.sortAthleteCards()
                    }
                });
            },
            sortAthleteCards() {
                this.cards.forEach(card => {
                    this.athleteCards = this.athleteCards.filter(athlete => {
                        return athlete._id !== card._id
                    })
                })
            },
            changeStatus(event) {
                this.status = event.target.value
            },
            savePage() {
                if(window.confirm("Are you sure you want to save element?")) {
                    let page = {
                        name: this.pageName,
                        cards: this.cards,
                        type: this.page.type,
                        author: this.author,
                        paragraphs: this.paragraphs,
                        richText: this.body,
                        img: this.img,
                        status: this.status,
                    };
                    this.pageService.updatePage(this.$cookies.get('user'), this.page._id, page).then(() => {
                        alert("Success!");
                        this.reloadPages();
                    }).catch(() => {
                        this.reloadPages();
                        alert("Something went wrong, please try again!")
                    })
                }
            },
            reloadPages() {
                this.pageService.findAllPages().then(pages => {
                    this.pages = pages;
                    this.page = pages[0];
                    this.cards = pages[0].cards;
                    this.type = pages[0].type;
                    this.status = pages[0].status;
                    this.pageName = pages[0].name;
                    this.author = pages[0].author;
                    this.body = pages[0].richText;
                    this.img = pages[0].img;
                    this.allPages = pages;
                }).then(() => {
                    this.cardService.findAllCards().then(cards => {
                        this.allCards = cards
                    }).then(() => {
                        this.filterPages('')
                    })
                });
            },
            deletePage() {
                if(window.confirm("Are you sure you want to delete this element?")) {
                    this.pageService.deletePage(this.$cookies.get('user'), this.page._id).then(() => {
                        alert("Success");
                        this.reloadPages();
                    }).catch(() => {
                        alert("Something went wrong, please try again")
                        this.reloadPages();
                    })
                }
            },
            deleteCard(card) {
                for(let i = 0; i < this.cards.length; i++){
                    if ( this.cards[i]._id === card._id) {
                        this.athleteCards.push(this.cards[i])
                        this.cards.splice(i, 1)
                    }
                }
            },
            isEmpty(obj) {
                for(let prop in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                        return false;
                    }
                }
                return true;
            },
            addCard() {
                if(this.isEmpty(this.dropdownPage) !== false) {
                    let canAdd = true;
                    this.cards.forEach((card) => {
                        if(this.card.name === card.name) {
                            canAdd = false
                        }
                    });
                    if(canAdd) {
                        let newCards = this.cards;
                        newCards.push(this.card);
                        this.cards = newCards
                        this.athleteCards = this.athleteCards.filter(athlete => this.card._id !== athlete._id)
                    } else {
                        alert('Card already added')
                    }
                }
            },
            selectCard(event) {
                if (event !== "---") {
                    this.cardService.findCardByName(event.target.value).then(card =>
                        this.card = card
                    );
                }
            },
            changeRichText(event) {
                this.body = event.getHTML()
            },
            sortCards(cards) {
                cards.forEach((card) => {
                    if(card.type === 'ATHLETE') {
                        this.athleteCards.push(card)
                    } else if (card.type === 'COACH') {
                        this.coachCards.push(card)
                    } else if (card.type === 'CONTENT') {
                        this.contentCards.push(card)
                    }
                })

            }
        },
        mounted() {
            this.pageService = PageService.getInstance();
            this.cardService = CardService.getInstance()
            this.pageService.findAllPages().then(pages => {
                this.pages = pages;
                this.page = pages[0];
                this.cards = pages[0].cards;
                this.type = pages[0].type;
                this.status = pages[0].status;
                this.pageName = pages[0].name;
                this.author = pages[0].author;
                this.body = pages[0].richText;
                this.img = pages[0].img;
                this.allPages = pages;
            }).then(() => {
                this.cardService.findAllCards().then(cards => {
                    this.allCards = cards;
                    this.sortCards(cards)
                });
            });

        }
    }
</script>

<style scoped>

    .allPages {
        background-color: firebrick;
        margin-top: 2%;
        color: white;
        margin-right: 2%;
    }

    .allPages:hover {
        background-color: black;
        color: white;
    }

    .pages {
        text-align: center;
    }

    .inputBox {
        width: 80%;
    }
    .cardsForPage {
        width: 150px;
    }

    .cardClear {
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        height: 14px;
        margin: auto;
        font-size: 14px;
        cursor: pointer;
        color: #ccc;
    }

    .addDropdown {
        background-color: navy;
        color: white;
        margin: 1%;
        width: 90%;
    }

    .groupOfCards, #imageInput {
        margin: 1%;
    }

</style>
