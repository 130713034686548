<template>
    <div class="athletePageContainer">
        <h1 class="athletePageHeader">{{page.name}}</h1>
        <div class="filters">
            <div class="filterContainer">
                <h3>Gender</h3>
                <select class="athleteDropdown" @change="filter($event, 'GENDER')">
                    <option disabled selected value="0"> -- select an option -- </option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                </select>
            </div>
            <div class="filterContainer">
                <h3>Event Group</h3>
                <select class="athleteDropdown" @change="filter($event, 'EVENT')">
                    <option disabled selected value> -- select an option -- </option>
                    <option value="MARATHON">Marathoners</option>
                    <option value="ROAD RUNNER">Road Runners</option>
                    <option value="XC">Cross Country Athletes</option>
                    <option value="TRACK">Track Athletes</option>
                    <option value="FIELD">Field Athletes</option>
                </select>
            </div>
            <div class="filterContainer">
                <h3>Age Group</h3>
                <select class="athleteDropdown" @change="filter($event, 'AGE')">
                    <option disabled selected value> -- select an option -- </option>
                    <option value="OPEN">Open</option>
                    <option value="MASTERS">Masters</option>
                </select>
            </div>

            <div v-if="filtered" class="clearFilters" @click="resetFilters()">
                Clear Filters
            </div>
            <div class="athleteCardContainer" v-for="athlete in athletes" :key="athlete.name">
                <h3 class="athleteHeader"> {{athlete.name}} </h3>
                <div class="athleteCard">
                    <img class="athletePhoto"
                         :src="athlete.img" :alt="athlete.name"/>
                    <div class="athleteHoverContent">
                        <div><b class="border-bottom">Gender: </b> {{athlete.gender}}</div>
                        <div><b class="border-bottom">Event(s): </b><div class="d-inline" v-for="event in athlete.eventGroup" :key="event">{{event}}, </div></div>
                        <div><b class="border-bottom">Age Group: </b>{{athlete.ageGroup}}</div>
                        {{athlete.content}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AthletesComponent",
        props: ['page'],
        data() {
            return {
                allAthletes: this.page.cards,
                athletes: this.page.cards,
                filtered: false,
                eventFilter: "",
                genderFilter: "",
                ageFilter: ""
            }
        },
        methods: {
            filter(event, key) {
                this.athletes = this.allAthletes;
                if(key === 'GENDER') {
                    this.filterGender(event.target.value)
                } else if(key === 'EVENT') {
                    this.filterEventGroup(event.target.value)
                } else if(key === 'AGE') {
                    this.filterAgeGroup(event.target.value)
                }

                if(this.eventFilter !== "") {
                    this.filterEventGroup(this.eventFilter)
                }
                if(this.genderFilter !== "") {
                    this.filterGender(this.genderFilter)
                }
                if(this.ageFilter !== "") {
                    this.filterAgeGroup(this.ageFilter)
                }

            },
            filterEventGroup(string) {
                let athletes = [];
                this.athletes.map((athlete) => {
                    athlete.eventGroup.map((group) => {
                        if (group === string) {
                            athletes.push(athlete);
                        }
                    })
                });
                this.athletes = athletes;
                this.eventFilter = string;
                this.filtered = true;
            },
            filterAgeGroup(string) {
                let athletes = [];
                this.athletes.map((athlete) => {
                    if (athlete.ageGroup === string) {
                        athletes.push(athlete);
                    }
                });
                this.athletes = athletes;
                this.ageFilter = string;
                this.filtered = true;
            },
            filterGender(string) {
                let athletes = [];
                this.athletes.map((athlete) => {
                    if (athlete.gender === string) {
                        athletes.push(athlete);
                    }
                });
                this.athletes = athletes;
                this.genderFilter = string;
                this.filtered = true;
            },
            resetFilters() {
                var options = document.querySelectorAll('.athleteDropdown');
                for (var i = 0, l = options.length; i < l; i++) {
                    options[i].selectedIndex = 0;
                }
                this.athletes = this.allAthletes;
                this.filtered = false;
                this.eventFilter = "";
                this.genderFilter = "";
                this.ageFilter = "";
            }
        }
    }
</script>

<style scoped>
    .athletePageContainer {
        background-color: darkgrey;
        border-radius: 50px;
        height: 100%;
        width: 100%;
        text-align: center;
    }

    .athleteFilter {
        margin: 2% auto 2%;
        border-radius: 5px;
        text-align: center;
    }

    .athleteHeader {
        text-align: center;
    }

    .clearFilters {
        color: blue;
        font-size: 20px;
        cursor: pointer;
        margin: 2% auto 2%;
    }

    .clearFilters:hover {
        text-decoration: underline;
    }

    .athleteDropdown {
        border-radius: 5px;
        box-shadow: 0 0 5px;
        border: 1px solid;
        margin: 0 0 0 25px;
        padding: 5px;
    }

    .athleteCardContainer {
        text-align: center;
        height: 300px;
        perspective: 600px;
        display: inline-block;
        z-index: -199;
        margin-top: 1%;
        margin-bottom: 10%;
    }
    .athleteCard {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .athletePhoto {
        border:1px solid #021a40;
        border-radius: 15%;
        height: 90%;
        width: 90%;
        background-color: black;
        object-fit: contain;
    }
    .athleteHoverContent {
        text-align: center;
        background: rgba(20, 20, 40, .8);
        position: absolute;
        top: 0;
        left: 5%;
        border-radius: 15%;
        right: auto;
        bottom: 0;
        opacity: 0;
        width: 90%;
        height: 90%;
        transition: 1s ease;
        color: white;
        font-size: 1.3rem;
        overflow: auto;
    }
    .athleteCard:hover .athleteHoverContent {
        opacity: 1;
    }
    .athletePageHeader {
        top: 50%;
        text-align: center;
        color: white;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2%;
        width: 100%;
        -webkit-text-stroke: 1px black;
        font-weight: bold;
        font-size: 60px;
        background: rgba(255,0,0, 0.9);
    }
    .filters {
        width: 100%;
        display: block;
        left: 0;
    }
    .filterContainer {
        width: 33%;
        display: inline-block;
    }
    .athleteDropdown {
        width: 50%;
    }
    @media (min-width: 769px) {
        .athleteCardContainer {
            width: 20%;
        }
    }
    @media (max-width: 769px) {
        .athleteCardContainer {
            width: 100%;
        }
        .athleteDropdown {
            width: 80%;
        }
    }

    @media (max-width: 500px) {
        .athleteDropdown {
            width: 90%;
        }
    }

</style>
