<template>
    <div class="editor">
        <editor-menu-bubble :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
            <div
                    class="editor__floating-menu"
                    :class="{ 'is-active': menu.isActive }"
                    :style="`top: ${menu.top}px`"
            >
                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold">
                    <font-awesome-icon :icon="faBold" />
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic">
                    <font-awesome-icon :icon="faItalic" />
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.strike() }"
                        @click="commands.strike">
                    <font-awesome-icon :icon="faStrikethrough" />
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline">
                    <font-awesome-icon :icon="faUnderline" />
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.code() }"
                        @click="commands.code">
                    <font-awesome-icon :icon="faCode" />
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.paragraph() }"
                        @click="commands.paragraph">
                    <font-awesome-icon :icon="faParagraph" />
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                        @click="commands.heading({ level: 1 })">
                    H1
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                        @click="commands.heading({ level: 2 })">
                    H2
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                        @click="commands.heading({ level: 3 })">
                    H3
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.bullet_list() }"
                        @click="commands.bullet_list">
                    <font-awesome-icon :icon="faList" />
                </button>

                <button
                        class="menubar__button"
                        :class="{ 'is-active': isActive.ordered_list() }"
                        @click="commands.ordered_list">
                    <font-awesome-icon :icon="faListOl" />
                </button>
                <button
                        class="menubar__button"
                        @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
                >
                    Table
                </button>
                <span v-if="isActive.table()">
						<button
                                class="menubar__button"
                                @click="commands.deleteTable"
                        >
                            <font-awesome-icon :icon="['fa', 'trash']"  />
						</button>
						<button
                                class="menubar__button"
                                @click="commands.addColumnBefore"
                        >
                            <font-awesome-icon :icon="['fa', 'arrow-left']"  />
						</button>
						<button
                                class="menubar__button"
                                @click="commands.addColumnAfter"
                        >
                            <font-awesome-icon :icon="['fa', 'arrow-right']"  />
						</button>
						<button
                                class="menubar__button"
                                @click="commands.deleteColumn"
                        >
							Delete Column
						</button>
						<button
                                class="menubar__button"
                                @click="commands.addRowBefore"
                        >
                            <font-awesome-icon :icon="['fa', 'arrow-up']"  />
						</button>
						<button
                                class="menubar__button"
                                @click="commands.addRowAfter"
                        >
                            <font-awesome-icon :icon="['fa', 'arrow-down']"  />
						</button>
						<button
                                class="menubar__button"
                                @click="commands.deleteRow"
                        >
							Delete Row
						</button>
						<button
                                class="menubar__button"
                                @click="commands.toggleCellMerge"
                        >
							Merge Cells
                        </button>
                </span>
                <button
                        class="menubar__button"
                        @click="showImagePrompt(commands.image)"
                >
                    <font-awesome-icon :icon="['fa', 'image']"  />
                </button>

                    <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                    <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                    </form>

                    <template v-else>
                    <button
                        class="menububble__button"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                        :class="{ 'is-active': isActive.link() }"
                    >
                        <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                    </button>
                    </template>
            </div>
        </editor-menu-bubble>



        <editor-content class="editor__content" :editor="editor" @change="changeRichText"/>
    </div>
</template>

<script>

    import { Editor, 
    EditorContent, 
    // EditorFloatingMenu, 
    EditorMenuBubble
     } from 'tiptap'
    import {
        Blockquote,
        CodeBlock,
        HardBreak,
        Heading,
        HorizontalRule,
        OrderedList,
        BulletList,
        ListItem,
        TodoItem,
        TodoList,
        Bold,
        Code,
        Italic,
        Link,
        Strike,
        Underline,
        History,
        Image,
        Table,
        TableHeader,
        TableCell,
        TableRow,
    } from 'tiptap-extensions'
    import {faBold, faItalic, faStrikethrough, faUnderline, faCode,
        faParagraph, faList, faListOl} from '@fortawesome/free-solid-svg-icons';

    export default {
        components: {
            EditorContent,
            // EditorFloatingMenu,
            EditorMenuBubble,
        },
        props: {
            richText: {type: String},
            changeRichText: {type: Function}
        },
        computed: {
            faBold() {
                return faBold
            },
            faItalic() {
                return faItalic
            },
            faStrikethrough() {
                return faStrikethrough
            },
            faUnderline() {
                return faUnderline
            },
            faCode() {
                return faCode
            },
            faParagraph() {
                return faParagraph
            },
            faList() {
                return faList
            },
            faListOl() {
                return faListOl
            }
            },
        data() {
            return {
                      keepInBounds: true,
                body: "",
                linkUrl: null,
                linkMenuIsActive: false,

                editor: new Editor({
                    extensions: [
                        new Blockquote(),
                        new BulletList(),
                        new CodeBlock(),
                        new HardBreak(),
                        new Heading({ levels: [1, 2, 3] }),
                        new HorizontalRule(),
                        new ListItem(),
                        new OrderedList(),
                        new TodoItem(),
                        new TodoList(),
                        new Link(),
                        new Bold(),
                        new Code(),
                        new Italic(),
                        new Strike(),
                        new Underline(),
                        new History(),
                        new Image(),
                        new Table({
                            resizable: true,
                        }),
                        new TableHeader(),
                        new TableCell(),
                        new TableRow(),
                    ],
                    content: this.richText,
                    onUpdate: this.changeRichText
                }),
            }
        },
        methods: {
            showImagePrompt(command) {
                const src = prompt('Enter the url of your image here')
                if (src !== null) {
                    command({ src })
                }
            },
            showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
            },
            hideLinkMenu() {
            this.linkUrl = null
            this.linkMenuIsActive = false
            },
            setLinkUrl(command, url) {
            command({ href: url })
            this.hideLinkMenu()
            },
        },
        beforeDestroy() {
            this.editor.destroy()
        },
    }
</script>

<style>
    .editor__content th {
        border: 1px solid dimgrey;
    }
    .editor__content td {
        border: 1px solid dimgrey;
    }
    .editor__content img{
        width: 200px;
        height: 200px;
        border: solid red 5px;
        border-radius: 25px;
    }
    .editor {
        position: relative;
    }
    .editor__floating-menu {
        position: absolute;
        z-index: 1;
        margin-top: -0.25rem;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s, visibility 0.2s;
    }
    .editor__floating-menu.is-active {
        opacity: 1;
        visibility: visible;
    }
 
</style>
