<template>
  <div class="home">
    <Carousel :featuredArticle="featuredPage" :articleOne="twoArticles[0]" :articleTwo="twoArticles[1]"/>
    <!-- <h1 class="instaHeader">Instagram</h1>
    <div class="instaContainer">
      <vue-instagram token="474902869772010|8492af79052d2aebdf70f85a56e4f9b3" :count="6" class="row">
        <template v-slot:loading="props">
          <h1 v-if="props.loading" class="fancy-loading">Loading, please wait...</h1>
        </template>
        <template v-slot:feeds="props">
          <div class="instaCard">
            <img class="instaPhoto"
                :src="props.feed.images.standard_resolution.url" alt="Instagram Post"/>
            <div class="instaHover">
              {{props.feed.caption.text.slice(0, 250) + '...'}}
              <a :href="props.feed.link" target="_blank">See Post</a>
            </div>
          </div>
        </template>
        <template v-slot:error="props">
          <div class="fancy-alert"> {{ props.error.error_message }} </div>
        </template>
      </vue-instagram>
    </div> -->
    <h1 class="instaHeader">Recent Articles</h1>
    <div class="articleContainer">
      <Card v-for="article in articles" :key="article._id"
            :image="article.img" :author="article.author" :date="article.updatedAt"
            :name="article.name" :content="article.richText.slice(0, 250) + '...'"></Card>
    </div>
    <button class="cardsButton btn btn-block" @click="routeToCards">See more Articles</button>
  </div>

</template>

<script>
  import Carousel from "../../assets/Carousel";
  import Card from "../../assets/Card";
  import PageService from '../../services/PageService';
  // import VueInstagram from 'vue-instagram';

  export default {
    name: 'HomePage',
    components: {Card, Carousel,
    //  VueInstagram
     },
    data() {
      return {
        articles: {},
        articleOneName: "",
        articleOneId: "",
        articleOneImage: "",
        articleTwoName: "",
        articleTwoId: "",
        articleTwoImage: "",
        twoArticles: [],
        featuredPage: {}
      }
    },
    methods: {
      routeToCards() {
        this.$router.push('/articles')
      },
      sortArticles(articles) {
        if(articles) {
          articles.sort((a, b) => {
            let date1 = a.updatedAt;
            let date2 = b.updatedAt;
            let comparison = 0;
            if (date1 < date2) {
              comparison = 1;
            } else if (date1 > date2) {
              comparison = -1;
            }
            return comparison;
          });

        }
        if(articles.length > 5) {
          return articles.slice(0, 5);
        } else {
          return articles
        }
      },
      openNewTab(url) {
        window.open(url, "_blank");
      }
    },
    mounted() {
      this.pageService = PageService.getInstance();
      this.pageService.findPageByName('HomePage').then(page => {
        this.featuredPage = page.featuredPage
      }).then(() => {
        this.pageService.findAllPages().then((pages) => {
          if (pages.length >= 2) {
            let articles = [];
            let i = 0;
            let twoArticles = [];
            pages = this.sortArticles(pages);
            pages.forEach(page => {
              if (page.type === 'ARTICLE' && page.status === 'PUBLISHED') {
                if (i < 2 && page.name !== this.featuredPage.name) {
                  twoArticles.push(page);
                  i++
                }
                articles.push(page)
              }
            });
            this.articles = articles;
            this.articleOneName = twoArticles[0].name;
            this.articleOneId = twoArticles[0]._id;
            this.articleOneImage = twoArticles[0].img;
            this.articleTwoName = twoArticles[1].name;
            this.articleTwoId = twoArticles[1]._id;
            this.articleTwoImage = twoArticles[1].img;
            this.twoArticles = twoArticles
          } else {
            this.articles = [];
            this.articleOneName = "GBTC";
            this.articleOneId = "123";
            this.articleOneImage = "";
            this.articleTwoName = "GBTC";
            this.articleTwoId = 123;
            this.articleTwoImage = '';
            this.twoArticles = []
          }
        })
      });
    }
  }
</script>

<style scoped>
  .instaContainer {
    background-color: red;
  }
  .instaCard {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
  }
  .instaPhoto {
    border:1px solid #021a40;
    border-radius: 15%;
    height: 100%;
    width: 60%;
  }
  .instaHover {
    text-align: center;
    margin-left: auto;
    margin-top: auto;
    background: rgba(20, 20, 40, .8);
    position: absolute;
    top: 0;
    left: 20%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2rem;
    border-radius: 15%;
    right: auto;
    bottom: 0;
    opacity: 0;
    width: 60%;
    transition: 1s ease;
    color: white;
  }
  .instaCard:hover .instaHover {
    opacity: 1;
  }
  .home {
  }
  .instaHeader {
    text-align: center;
    margin-top: 5%;
    color: #FF4136;
    font-weight: bold;
    font-size: 50px;
    text-shadow: 0 0 2px #000; /* horizontal-offset vertical-offset 'blur' colour */
    -moz-text-shadow: 0 0 2px #000;
    -webkit-text-shadow: 0 0 2px #000;
  }
  .mt-4 {
    margin-left: auto;
    margin-right: auto;
  }
  .articleContainer {
    margin-top: 2%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .cardsButton {
    margin: auto;
    width: 50%;
    height: 40px;
    color: white;
    font-size: 1.5rem;
    background-color: #2c3e50;
  }

  .cardsButton:hover {
    background-color: black;
    color: white;
    text-decoration: underline;
  }

  .row {
    margin-top: 2%;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .instaCards {
    background-color: #DDDDDD;
    height: 400px;
    display: inline-block;
    max-width: 20rem;
    margin-left: auto;
    margin-top: 2%;
    margin-right: auto;
    text-align: center;
  }

  @media (max-width: 900px) {
    .instaPhoto {
      width: 60%;
    }
    .instaHover {
      width: 60%;
      left: 20%;
      font-size: 1.6rem;
    }
    .articleContainer {
      width: 60%;
    }
  }


  @media (max-width: 700px) {
    .instaPhoto {
      width: 70%;
    }
    .instaHover {
      width: 70%;
      left: 15%;
      font-size: 1.6rem;
    }
    .articleContainer {
      width: 70%;
    }
  }

  @media (max-width: 500px) {
    .instaPhoto {
      width: 80%;
    }
    .instaHover {
      width: 80%;
      left: 10%;
      font-size: 1.4rem;
    }
    .articleContainer {
      width: 80%;
    }
  }

</style>
