<template>
    <b-card
            :img-src="image"
            img-alt="Card image"
            :footer="`Author: ` + author"
            img
            footer-tag="footer"
            img-top
            class="articleCard mb-3">
        <h1 class="articleHeaderContainer">
            <router-link class="articleHeader" :to="`/page/${name}`">{{name}}</router-link>
        </h1>
        <b-card-text>{{cardContent}}</b-card-text>
    </b-card>
</template>

<script>
    export default {
        name: "CardComponent",
        props: ['image', 'author', 'date', 'name', "content"],
        data() {
            return {
                cardContent: ""
            }
        },
        mounted() {
            var tmp = document.createElement("DIV");
            tmp.innerHTML = this.content;
            this.cardContent = tmp.textContent || tmp.innerText || " ";
        }
    }
</script>

<style scoped>
    .articleHeaderContainer {
        text-align: center;
        width: 100%;
        background-color: red;
        border-radius: 15px;
    }
    .articleHeader {
        color: white;
        margin-left: auto;
        text-align: center;
        margin-right: auto;
        background-color: red;
    }
    .articleCard h4 {
        font-weight: bold;
        background-color: red;
        font-size: 20px;
        color: white;
        text-align: center;
    }
    .articleCard {
        width: 100%;
        color: black;
        font-size: 16px;
        background-color: aliceblue;
    }
    @media screen and (max-width: 700px) {
        .card-img-top {
            max-height: 400px;
        }
    }

    @media screen and (max-width: 500px) {
        .card-img-top {
            max-height: 400px;
        }
    }
</style>
