import {backendURL} from '../environment';

let API_URL = backendURL + 'api/';

export default class EventRegistrationService {
    static myInstance = null;

    static getInstance() {
        if (EventRegistrationService.myInstance == null) {
            EventRegistrationService.myInstance = new EventRegistrationService();
        }
        return this.myInstance
    }

    findAllEvents = () =>
        fetch(API_URL + "events", {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

    updateEvent = (token, eventId, event) => {
        return fetch(API_URL + "events/" + eventId, {
            method: 'put',
            body: JSON.stringify(event),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };
    createEvent = (token, event) => {
        return fetch(API_URL + "events", {
            method: 'post',
            body: JSON.stringify(event),
            headers: {
                'content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response => response.json())
            .catch(() => alert("Event Could Not Be Created"));
    };

    findEventById = (id) =>
        fetch(API_URL + 'events/' + id)
            .then(response => response.json());

    findEventByName = (eventName) =>
        fetch(API_URL + "events/name/" + eventName, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

    register = (registrant, eventId) => {
        return fetch(API_URL + "events/register/" + eventId, {
            method: 'put',
            body: JSON.stringify(registrant),
            headers: {
                'content-type': 'application/json'
            }
        }).then(response =>
            response.json());
    }

    deleteEvent = (token, eventId) => {
        return fetch(API_URL + "events/" + eventId, {
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };




}
