<template>
    <div :key="type">
        <h1 id="pageHeader" class="editorHeader">New Page</h1>
        <div  class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Page Type: {{type}}</h3>
            <div class="editorContentContainer">
                <select @change="changeType"
                        class="inputBox form-control">
                    <option style="display: none" :value="type">{{type}}</option>
                    <option value="COACH">Coach</option>
                    <option value="ATHLETE">Athlete</option>
                    <option value="ARTICLE">Article</option>
                </select>
            </div>
        </div>
        <PlainTextEditor heading="Name of Page" :val="pageName" :parentMethod="pageNameChange"/>
        <PlainTextEditor heading="Author of Page" :val="author" :parentMethod="authorChange"/>
        <div v-if="page.type === 'CARDS'" id="pageCards" class="editorContentContainer border border-dark">
            <h3 id="pageCardHeader" class="editorContentHeader">Page's Cards</h3>
            <div v-for="card in cards" :key="card._id" class="groupOfCards btn-group">
                <input type="text" class="cardsForPage form-control" :value="card.name" readonly/>
                <span @click="deleteCard(card)" class="cardClear fa fa-times">
                    <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                </span>
            </div>
            <select @change="selectCard" id="selectCard" class="inputBox form-control">
                <option style="display: none" selected="main">---</option>
                <option v-for="card in contentCards" :key="card._id" :value="card.name">{{card.name}}</option>
            </select>
            <button @click="addCard" class="addDropdown btn btn-block">Add Card</button>
        </div>
        <div v-if="type === 'ARTICLE'">
            <div class="editorContentContainer border border-dark">
                <h3 class="editorContentHeader">Page Content</h3>
                <div class="richTextEditorContainer">
                    <RichTextEditor :richText="body" :changeRichText="changeRichText"/>
                </div>
            </div>
            <ImageUpload :image="img" :parent-file-change="fileChange"/>
        </div>
        <div v-if="type === 'ATHLETE'" class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Page's Cards</h3>
            <div v-for="card in cards" :key="card._id" class="groupOfCards btn-group">
                <input type="text" class="cardsForPage form-control" :value="card.name" readonly/>
                <span @click="deleteCard(card)" class="cardClear fa fa-times">
                    <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                </span>
            </div>
            <select @change="selectCard" class="inputBox form-control">
                <option style="display: none" selected="main">---</option>
                <option v-for="card in athleteCards" :key="card._id" :value="card.name">{{card.name}}</option>
            </select>
            <button @click="addCard" class="addDropdown btn btn-block">Add Card</button>
        </div>
        <div v-if="type === 'COACH'" class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Page's Cards</h3>
            <div v-for="card in cards" :key="card._id" class="groupOfCards btn-group">
                <input type="text" class="cardsForPage form-control" :value="card.name" readonly/>
                <span @click="deleteCard(card)" class="cardClear fa fa-times">
                    <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                </span>
            </div>
            <select @change="selectCard" class="inputBox form-control">
                <option style="display: none" selected="main">---</option>
                <option v-for="card in coachCards" :key="card._id" :value="card.name">{{card.name}}</option>
            </select>
            <button @click="addCard" class="addDropdown btn btn-block">Add Card</button>
        </div>
        <div  class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Page Status: {{status}}</h3>
            <div class="editorContentContainer">
                <select @change="changeStatus"
                        class="inputBox form-control">
                    <option style="display: none" :value="status">{{status}}</option>
                    <option>PUBLISHED</option>
                    <option>DRAFT</option>
                </select>
            </div>
        </div>
        <button @click="createPage" class="createPage btn btn-block btn-success">Create Page</button>
    </div>

</template>

<script>
    import PageService from "../../../services/PageService";
    import CardService from "../../../services/CardService";
    import PlainTextEditor from "../../../assets/PlainTextEditor";
    import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
    import RichTextEditor from "../../../assets/RichTextEditor";

    import axios from "axios";
    import ImageUpload from "../../../assets/ImageUpload";
    import {backendURL} from '../../../environment';

    export default {
        name: "PageEditor",
        components: {ImageUpload, RichTextEditor, PlainTextEditor},
        data() {
            return {
                cards: [],
                contentCards: [],
                coachCards: [],
                athleteCards: [],
                allCards: [],
                type: "",
                status: "",
                pageName: "",
                author: "",
                body: "",
                card: {},
                page: {},
                img: ""
            }
        },
        computed: {
            timesIcon(){
                return faTimes
            },
            richText(){
                return this.body
            }
        },
        methods: {
            fileChange(e) {
                if(window.confirm("Are you sure you want to upload this image?")) {
                    const allowedTypes = ["image/jpeg", "image,jpg", "image/png"];
                    var files = e.target.files || e.dataTransfer.files;
                    const file = files[0];
                    if (!allowedTypes.includes(file.type)) {
                        alert("Wrong Type!");
                        return;
                    }
                    if (file.size > 500000) {
                        alert("File too large!");
                        return;
                    }
                    let formData = new FormData();
                    formData.append('file', file);
                    axios.post(backendURL + 'api/pages/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(responso => {
                        this.img = (responso.data.location);
                    }).catch(() => {
                        alert("Something went wrong uploading the image, please try again")
                    })
                }
            },
            createPage() {
                let page = {
                    cards: this.cards,
                    name: this.pageName,
                    author: this.author,
                    type: this.type,
                    status: this.status,
                    richText: this.body,
                    img: this.img
                };
                if(page.type === '' || page.name === '' || page.status === '') {
                    alert("Page name, type and status required")
                } else if(window.confirm("Are you sure you want to create this page?")) {
                    this.pageService.createPage(this.$cookies.get('user'), page).then(page =>{
                        alert(page.name + " created successfully!");
                        this.cards = [];
                        this.type = "";
                        this.status = "";
                        this.pageName = "";
                        this.author = "";
                        this.body = "";
                        this.card = {};
                        this.page = {};
                        this.img = "";
                    })
                }
            },
            pageNameChange(event) {
                this.pageName = event.target.value
            },
            authorChange(event) {
                this.author = event.target.value
            },
            changeStatus(event) {
                this.status = event.target.value
            },
            deleteCard(card) {
                for(let i = 0; i < this.cards.length; i++){
                    if ( this.cards[i]._id === card._id) {
                        this.cards.splice(i, 1)
                    }
                }
            },
            isEmpty(obj) {
                for(let prop in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                        return false;
                    }
                }
                return true;
            },
            addCard() {
                if(this.isEmpty(this.dropdownPage) !== false) {
                    let canAdd = true;
                    this.cards.forEach((card) => {
                        if(this.card.name === card.name) {
                            canAdd = false
                        }
                    });
                    if(canAdd) {
                        let newCards = this.cards;
                        newCards.push(this.card);
                        this.cards = newCards
                    } else {
                        alert('Card already added')
                    }
                }
            },
            selectCard(event) {
                if (event !== "---") {
                    this.cardService.findCardByName(event.target.value).then(card =>
                        this.card = card
                    );
                }
            },
            changeRichText(event) {
                this.body = event.getHTML()
            },
            sortCards(cards) {
                cards.forEach((card) => {
                    if(card.type === 'ATHLETE') {
                        this.athleteCards.push(card)
                    } else if (card.type === 'COACH') {
                        this.coachCards.push(card)
                    } else if (card.type === 'CONTENT') {
                        this.contentCards.push(card)
                    }
                })

            },
            changeType(event) {
                this.type = event.target.value
            }
        },
        mounted() {
            this.pageService = PageService.getInstance();
            this.cardService = CardService.getInstance();
            this.cardService.findAllCards().then(cards => {
                this.allCards = cards;
                this.sortCards(cards)
            });
        }
    }
</script>

<style scoped>
    .inputBox {
        width: 80%;
    }
    .cardsForPage {
        width: 150px;
    }

    .cardClear {
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        height: 14px;
        margin: auto;
        font-size: 14px;
        cursor: pointer;
        color: #ccc;
    }

    .addDropdown {
        background-color: navy;
        color: white;
        margin: 1%;
        width: 90%;
    }

    .groupOfCards {
        margin: 1%;
    }

    .createPage {
        width: 100%;
        background-color: green;
        margin-top: 2%;
        margin-bottom: 2%;
    }

</style>
