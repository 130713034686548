import {backendURL} from '../environment';

const API_URL = backendURL + 'api/';
import Vue from 'vue';

export default {
    getNavItems: () => {
        return fetch(API_URL + "navs", {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.json()
        })
    },

    getPage: (pageName) => {
        return () => fetch(API_URL + 'pages/names/' + pageName, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.json()
        })
    },

    getRegistrant: state => {
        return state.registrant
    },

    loggedIn: (state) => {
        state.loggedIn = Vue.$cookies.isKey('user');
        return state.loggedIn
    },

    userType: (state) => {
        return state.userType
    }

}