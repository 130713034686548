<template>
    <div>
        <h1 class="editorHeader">Donations</h1>
        <div>
            <table class="table table-hover table-dark">
                <thead>
                <tr class="tableHeadingContainer">
                    <th scope="col" class="tableHeading col-xs-2">Name</th>
                    <th scope="col" class="tableHeading mobile col-xs-2">Email</th>
                    <th scope="col" class="tableHeading col-xs-1">Donation</th>
                    <th scope="col" class="tableHeading xs-screen col-xs-1">Phone</th>
                    <th scope="col" class="tableHeading xs-screen col-xs-1">City</th>
                    <th scope="col" class="tableHeading xs-screen col-xs-2">State</th>
                    <th scope="col" class="tableHeading xs-screen col-xs-2">Message</th>
                    <th scope="col" class="tableHeading action col-xs-3">Date Donated</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="donation in donations" :key="donation._id">
                    <td class="col-xs-2">
                        {{donation.name}}
                    </td>
                    <td class="col-xs-2 mobile">
                        {{donation.email}}
                    </td>
                    <td class="col-xs-1">
                        ${{donation.total}}
                    </td>
                    <td class="xs-screen col-xs-1">
                        {{donation.phone}}
                    </td>
                    <td class="xs-screen col-xs-1">
                        {{donation.city}}
                    </td>
                    <td class="xs-screen col-xs-2">
                        {{donation.state}}
                    </td>
                    <td class="xs-screen col-xs-2">
                        {{donation.message}}
                    </td>
                    <td class="col-xs-2">
                        {{createdAt(donation.createdAt)}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    import DonationService from "../../../services/DonationService";

    export default {
        name: "DonationsComponent",
        data() {
            return {
                donations: []
            }
        },
        methods: {
            createdAt(creationDate) {
                let date = new Date(creationDate);
                return date.toDateString()

            }
        },
        mounted() {
            this.donationService = DonationService.getInstance();
            this.donationService.findAllDonations().then(donations => {
                this.donations = donations
            })
        }
    }
</script>

<style scoped>
    @media (max-width: 850px) {
        .small-screen  {
            display: none;
        }
    }
    @media (max-width: 700px) {
        .xs-screen  {
            display: none;
        }
    }
    @media (max-width: 500px) {
        .mobile  {
            display: none;
        }
    }

</style>
