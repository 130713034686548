<template>
    <div  class="coachPageContainer">
        <h1 class="coachPageHeader">{{page.name}}</h1>
        <div class="coachCardContainer" v-for="coach in page.cards" :key="coach.name">
            <h3>{{coach.name}}</h3>
            <div :class="'coachCard ' + coach.name.replace(/\s/g, '')"
            @click="flipCard(coach.name.replace(/\s/g, ''))">
            <div class="coachCardFace coachCardFaceFront">
                <img class="coachPhoto"
                     :src="coach.img" alt="Rod"/>
            </div>
            <div class="coachCardFace coachCardFaceBack">
              <div class="scrollOverflowBorderHack">
                {{coach.content}}
              </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: "CoachesComponent",
        data() {
            return {
                coachClass: ""
            }
        },
        props: ['page'],
        methods: {
            flipCard(coach) {
                let coachClass = "." + coach;
                var card = document.querySelector(coachClass);
                card.classList.toggle('is-flipped');
            }
        }
    }
</script>

<style scoped>
    .scrollOverflowBorderHack{
      overflow-y: auto;
      height: 100%;
      width: 100%;
    }
    .coachPageContainer {
        background-color: darkgrey;
        border-radius: 50px;
        height: 100%;
        width: 100%;
    }
    .coachCardContainer {
        width: 33%;
        height: 400px;
        padding-left: 2%;
        perspective: 600px;
        display: inline-block;
    }

    @media screen and (max-width: 800px) {
        .coachCardContainer {
            width: 80%;
        }
    }

    @media screen and (max-width: 500px) {
        .coachCardContainer {
            width: 80%;
        }
    }
    .coachCard {
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        transition: transform 1s;
        transform-style: preserve-3d;
        margin-bottom: 20px;
    }
    .coachCardFace {
        position: absolute;
        height: 100%;
        width: 100%;
        border:1px solid #021a40;
        border-radius: 10%;
        backface-visibility: hidden;
    }
    .coachCardFaceFront {
        background: red;
    }

    .coachCardFaceBack {
        background: red;
        font-size: 18px;
        color: white;
        padding: 2%;
        transform: rotateY( 180deg );
    }
    .coachPhoto {
        border:1px solid #021a40;
        border-radius: 10%;
        width: 100%;
        height: 100%;
        object-fit: contain;

    }
    .is-flipped {
        transform: rotateY(180deg);
    }
    .coachPageHeader {
        top: 50%;
        text-align: center;
        color: white;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2%;
        width: 100%;
        -webkit-text-stroke: 1px black;
        font-weight: bold;
        font-size: 60px;
        background: rgba(255,0,0, 0.9);
    }
</style>
