import Vue from 'vue'

export default {
    updateRegistrant: (state, payload) =>{
        state.registrant = payload.registrant;
    },
    login: (state, payload) => {
        Vue.$cookies.config('7d');
        Vue.$cookies.set('user', payload.token);
        state.loggedIn = true;
        return state.loggedIn
    },
    logout: (state) =>{
        Vue.$cookies.remove('user');
        state.loggedIn = false;
        return state.loggedIn
    },
    setUserType: (state, payload) => {
        state.userType = payload.type;
        return state.userType
    },
    removeType: (state) => {
        state.userType = ''
    }
}