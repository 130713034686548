<template>
    <div class="userContainer">
        <h3 class="userName">{{user.name}}</h3>
        <div class="userDescription"><b class="descriptionHeader">Email:</b> {{user.email}}</div>
        <div class="userDescription"><b class="descriptionHeader">Gender:</b> {{user.gender}}</div>
        <div class="userDescription"><b class="descriptionHeader">Age Group:</b> {{user.ageGroup}}</div>
        <div class="userDescription"><b class="descriptionHeader">Events:</b></div>
        <ul>
            <li v-for="event in user.events" :key="event">{{event}}</li>
        </ul>
        <div class="userDescription"><b class="descriptionHeader">Previous Club/College: </b>{{user.previousClub}}</div>
        <div class="userDescription"><b class="descriptionHeader">Heard about us: </b>{{user.heardAbout}}</div>

        <p class="userDescription"><b class="descriptionHeader">Bio:</b> {{user.bio}}</p>
    </div>
</template>

<script>
    import MemberService from "../../../services/memberService";
    import Vue from "vue";

    export default {
        name: "ApplicantComponent",
        data() {
            return {
                user: {}
            }
        },
        mounted() {
            this.memberService = MemberService.getInstance()
            this.memberService.findMemberByName(Vue.$cookies.get('user'), this.$route.params.name).then((user) => {
                this.user = user[0]
            })
        }
    }
</script>

<style scoped>
    .userContainer {
        text-align: left;
    }

    .userName {
        text-align: center;
        font-size: 25px;
    }
    ul {
        font-size: 16px;
        list-style-position: inside;
    }
    .descriptionHeader {
        color: red;
    }

    .userDescription {
        font-size: 18px;
    }

</style>