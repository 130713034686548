<template>
    <div class="plainText editorContentContainer border border-dark">
        <h3 id="imageOfPage" class="editorContentHeader">{{heading}}</h3>
        <div id="changeImage" class="inputBox">
            <input class="form-control"
                   :value="val"
                   :type="type"
                   :placeholder="placeholder"
                   @change="parentMethod"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PlainTextEditor",
        props: {
            heading: String,
            type: String,
            placeholder: String,
            val: String,
            parentMethod: {type: Function}
        },
    }
</script>

<style scoped>
</style>