<template>
    <div class="carousel-container">
        <div class="slideshow-container">

            <div v-if="slide === 1" class="active">
                <div class="numbertext">1 / 3</div>
                <img class="carouselImage" height="600" width="2000" :src="featuredArticle.img" alt="alt"/>
                <h2 class="text">
                    <router-link :to="`/page/${featuredArticle.name}`">{{featuredArticle.name}}</router-link>
                </h2>
            </div>
            <div v-if="slide === 2" class="active">
                <div class="numbertext">2 / 3</div>
                <img class="carouselImage" height="600"  width="2000" :src="articleOne.img" alt="alt"/>
                <h2 class="text">
                    <router-link :to="`/page/${articleOne.name}`">{{articleOne.name}}</router-link>
                </h2>
            </div>
            <div v-if="slide === 3" class="active">
                <div class="numbertext">3 / 3</div>
                <img class="carouselImage" height="600" width="2000" :src="articleTwo.img" alt="alt"/>
                <h2 class="text">
                    <router-link :to="`/page/${articleTwo.name}`">{{articleTwo.name}}</router-link>
                </h2>
            </div>
        </div>
        <br/>
    </div>
</template>

<script>
    export default {
        name: "CarouselComponent",
        props: {
            featuredArticle: Object,
            articleOne: Object,
            articleTwo: Object
        },
        computed: {

        },
        data() {
            return {
                slide: 1
            }
        },
        methods: {
            onSlideStart() {
                this.sliding = true
            },
            onSlideEnd() {
                this.sliding = false
            },
            parseText(text) {
                var tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                return tmp.textContent || tmp.innerText || " ";
            },
            changeSlide() {
                let n = this.slide;
                if(n < 3) {
                    this.slide++
                } else if(n === 3) {
                    this.slide = 1
                }
            }
    },
        mounted() {
            window.setInterval(()=>{
                this.changeSlide();
            }, 7000);
        }
    }
</script>

<style>
    .carousel-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    * {box-sizing:border-box}

    /* Slideshow container */
    .slideshow-container {
        max-width: 2000px;
        z-index: 0;
        position: relative;
        margin: auto;
    }

    /* Caption text */
    .text {
        color: red;
        font-size: 30px;
        padding: 8px 12px;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
    }

    .text a {
        color: white;
    }

    /* Number text (1/3 etc) */
    .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
        border-radius: 5px;
    }

    .carouselImage {
        width: 100%;
        max-height: 100%;
        border: 4px solid red;
        background-color: red;
        border-radius: 5px;
        object-fit: contain;
    }

    @media screen and (max-width: 700px) {
        .carouselImage {
            max-height: 400px;
        }
    }

    @media screen and (max-width: 500px) {
        .carouselImage {
            max-height: 300px;
        }
    }
    .active {
        z-index: -100;
        width: 100%;
        height: 95%;
        display: block;
        -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 5s; /* Firefox < 16 */
        -ms-animation: fadein 5s; /* Internet Explorer */
        -o-animation: fadein 5s; /* Opera < 12.1 */
        animation: fadein 5s;
    }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }



</style>
