<template>
    <div class="eventCenter" :key="event._id">
        <BannerImage :bannerTitle="eventTitle" :bannerImg="event.img"/>
        <h1 class="editorHeader">{{eventTitle}}</h1>
        <div class="eventDetail">
            <b>Date:</b> {{date}}
        </div>
        <div class="eventDetail">
            <b>Location:</b> {{event.location}}
        </div>
        <p v-html="event.richText" class="richText"></p>
        <h2 v-if="event.onePayment === 'TRUE'">
            <b>{{event.name}} Price:</b> {{event.onePaymentPrice}}$
        </h2>
        <div v-for="race in event.events" :key="race.name" class="subEventContainer">
            <h3 class="eventDetail eventName">
                {{race.name}}
            </h3>
            <div>
                <b>Type:</b> {{race.distance}}
            </div>
            <div>
                <b>Time:</b> {{race.time}}
            </div>
            <div v-if="event.onePayment !== 'TRUE'">
                <b>Price:</b> {{race.price}}
            </div>
        </div>
        <div v-if="event.status === 'OPEN'" class="registerHere">
            <router-link :to="`./registration/${eventTitle}`">
                <button class="registerBtn btn btn-block">
                    Register Here
                </button>
            </router-link>
        </div>
        <div v-else-if="event.status === 'PRE'" class="eventNotOpen">
            <h2>{{event.name}} not open for registration yet! Come back soon!</h2>
        </div>
        <div v-else-if="event.status === 'CLOSED'" class="eventNotOpen">
            <h2>{{event.name}} registration closed</h2>
        </div>
        <div v-else-if="event.status == 'EXTERNAL'" class="eventExternalRegistration">
        </div>
    </div>
</template>

<script>
    import EventService from '../../services/EventRegistrationService'
    import BannerImage from "../../assets/BannerImage";
    export default {
        name: "EventComponent",
        components: {BannerImage},
        data() {
            return {
                event: {},
                eventTitle: "",
                date: ""
            }
        },
        methods: {

        },
        mounted() {
            this.eventService = EventService.getInstance();
            this.eventService.findEventByName(this.$route.params.name).then(event => {
                this.event = event;
                this.eventTitle = event.name;
                let date = new Date(event.date);
                this.date = date.toDateString();
            })
        }
    }
</script>

<style scoped>
    .subEventContainer {
        margin-top: 1%;
        border-style: solid;
        border-color: black;
        border-radius: 50px;
        width: 40%;
        margin-left: 1%;
        display: inline-block;
        padding: 1%;
        font-size: 15px;
    }
    .eventDetail {
        color: red;
        font-size: 20px;
        font-weight: bold;
    }
    .richText {
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        margin-top: 2%;
        font-size: 15px;
    }
    .eventCenter {
        text-align: center;
    }
    .registerHere {
        width: 25%;
        margin: 3% auto;
    }
    .eventNotOpen {
        margin: 3% auto;
    }
    .registerBtn {
        background-color: red;
        color: white;
        font-size: 15px;
    }
    .registerBtn:hover {
        background-color: black;
        color: white;
    }
</style>
