<template>
    <div>
        <h1 class="editorHeader">
            Member Management
        </h1>
        <div class="searchBar md-form mb-3 mt-0">
            <input class="form-control" type="text" placeholder="Search" aria-label="Search" @change="searchMembers">
        </div>
        <div class="filterButtonContainer" v-if="!filteredByExpiration">
            <button class="filterButton btn btn-primary" @click="filterByExpiration">See all members who haven't paid dues</button>
        </div>
        <div class="filterButtonContainer" v-else>
            <button class="filterButton btn btn-danger" @click="unfilter">See all members</button>
        </div>
        <div>
            <table class="table table-hover table-dark">
                <thead>
                <tr class="tableHeadingContainer">
                    <th scope="col" class="tableHeading col-xs-2">Name</th>
                    <th scope="col" class="tableHeading mobile col-xs-2">Email</th>
                    <th scope="col" class="tableHeading xs-screen col-xs-1">Age Group</th>
                    <th scope="col" class="tableHeading xs-screen col-xs-1">Gender</th>
                    <th scope="col" class="tableHeading xs-screen col-xs-1">Type</th>
                    <th scope="col" class="tableHeading xs-screen col-xs-2">Active Until:</th>
                    <th scope="col" class="tableHeading action col-xs-3">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="member in members" :key="member._id">
                    <td class="col-xs-2">
                        <router-link :to="`/members/members/${member.name}`">{{member.name}}</router-link>
                    </td>
                    <td class="col-xs-2 mobile">
                        {{member.email}}
                    </td>
                    <td class="xs-screen col-xs-1">
                        {{member.ageGroup}}
                    </td>
                    <td class="xs-screen col-xs-1">
                        {{member.gender}}
                    </td>
                    <td class="xs-screen col-xs-1">
                        {{member.type}}
                    </td>
                    <td class="xs-screen col-xs-2">
                        {{membershipDuration(member.membershipDuration)}}
                    </td>
                    <td class="col-xs-3">
                        <b-button v-if="member.type === 'MEMBER'" @click="promote(member._id, member)" class="btn upgrade">Promote</b-button>
                        <b-button v-if="member.type === 'ADMIN'" @click="demote(member._id, member)" class="btn demote">Demote</b-button>
                        <b-button @click="declineMember(member._id)" class="btn decline">Delete</b-button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="member-container">
            <router-view/>
        </div>
        <button class="reminderButton btn btn-primary" @click="testRenewals">Test email renewal reminders</button>

        <export-excel :data="allMembers"
                      class="btn btn-primary memberData"
                      worksheet="Member Worksheet"
                      :fields="fields"
                      type="csv"
                      name="member_data.xls"/>
    </div>
</template>

<script>
    import MemberService from "../../../services/memberService";
    import Vue from "vue";

    export default {
        name: "NewMember",
        data() {
            return {
                members: [],
                allMembers: [],
                filteredByExpiration: false
            }
        },
        methods: {
            renderMembers() {
                this.memberService.findAllMembers(Vue.$cookies.get('user')).then((members) => {
                    this.members = members;
                });
            },
            unfilter() {
                this.members = this.allMembers;
                this.filteredByExpiration = false
            },
            searchMembers(event) {
                this.members = this.allMembers.filter((member) => {
                    return member.name.toLowerCase().includes(event.target.value.toLowerCase())
                });
                this.filteredByExpiration = false
            },
            filterByExpiration() {
                this.members = this.allMembers.filter((member) => {
                    return new Date(member.membershipDuration) <= new Date()
                })
                this.filteredByExpiration = true
            },
            testRenewals() {
                this.members = this.allMembers.filter((member) => {
                    let d1 = new Date(member.membershipDuration);
                    let d2 = new Date();
                    let weeks = Math.floor(Math.abs(d1.getTime()-d2.getTime())/(24*3600*1000*7))
                    return weeks < 2 // membership is within 2 weeks of expiration
                })
            },
            membershipDuration(date) {
                let newDate = new Date(date);
                return newDate.toDateString()
            },
            declineMember(id) {
                if(window.confirm("Are you sure you want to REMOVE this member?")) {
                    this.memberService.declineMember(Vue.$cookies.get('user'), id).then(() => {
                        this.renderMembers()
                    }).catch(() => {
                        alert("Something went wrong please try again");
                        this.renderMembers();
                    })
                }
            },
            promote(id, member) {
                if(window.confirm("Are you sure you want to make this member an ADMIN?")) {
                    this.memberService.promote(Vue.$cookies.get('user'), id, member).then(() => {
                        this.renderMembers()
                    }).catch(() => {
                        alert("Something went wrong please try again");
                        this.renderMembers();
                    })
                }
            },
            demote(id, member) {
                if(window.confirm("Are you sure you want to take away this member's ADMIN role?")) {
                    this.memberService.demote(Vue.$cookies.get('user'), id, member).then(() => {
                        this.renderMembers()
                    }).catch(() => {
                        alert("Something went wrong please try again");
                        this.renderMembers();
                    })
                }
            }
        },
        mounted() {
            this.memberService = MemberService.getInstance();
            this.memberService.findAllMembers(Vue.$cookies.get('user')).then((members) => {
                this.members = members;
                this.allMembers = members;
            });
        }
    }
</script>

<style scoped>
    .searchBar {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .member-container {
        text-align: left;
    }

    .upgrade {
        width: 44%;
        margin-left: 3%;
        background-color: cadetblue;
    }

    .demote {
        width: 44%;
        margin-left: 3%;
        background-color: darkorange;
    }
    .memberData {
        width: 100%;
        margin-top: 2%;
    }
    .decline {
        width: 44%;
        background-color: red;
        margin-left: 3%;
    }

    .action{
        text-align: center;
    }
    .filterButtonContainer {
        text-align: center;
        width: 100%;
    }
    .filterButton {
        margin-bottom: 2%;
    }
    .reminderButton {
        margin-bottom: 2%;
    }

    @media (max-width: 850px) {
        .small-screen  {
            display: none;
        }
    }
    @media (max-width: 700px) {
        .xs-screen  {
            display: none;
        }
    }
    @media (max-width: 500px) {
        .mobile  {
            display: none;
        }
    }

</style>
