import axios from "axios";
import {backendURL} from '../environment';

export default {
    editRegistrant: ({commit}, payload) =>{
        commit('updateRegistrant', payload)
    },
    addUser: ({commit}, payload) => {
        commit('login', payload)
    },
    removeUser: ({commit}) => {
        commit('logout')
    },
    patchUserType: ({commit}, payload) => {
        axios.get(backendURL + `api/users/current`, {
            headers:
                {Authorization: 'Token ' + payload.token }
        })
            .then(response => {
                let payload = {
                    type: response.data.user.type
                };
                commit('setUserType', payload)
            })
            .catch(() => {
                let payload = {
                    type: 'ERROR'
                };
                commit('setUserType', payload)
            })
    },
    removeUserType: ({commit}) => {
        commit('removeType')
    }
}