<template>
    <div class="eventContainer">
        <h1 class="editorHeader">New Event</h1>
        <PlainTextEditor heading="Event Name" :val="event.name" :parentMethod="changeEventName"/>
        <ImageUpload :image="img" :parent-file-change="fileChange"/>
        <PlainTextEditor heading="Location: " :val="event.location" :parentMethod="changeLocation"/>
        <PlainTextEditor heading="Date: " type="date" :val="event.date" :parentMethod="changeDate"/>
        <div class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Event Content</h3>
            <div class="richTextEditorContainer">
                <RichTextEditor :richText="event.richText" :changeRichText="changeRichText"/>
            </div>
        </div>
        <div class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Event Status: {{event.status}}</h3>
            <div class="editorContentContainer inputBox">
                <select @change="changeStatus"
                        class=" form-control">
                    <option style="display: none" :value="event.status">{{event.status}}</option>
                    <option>DRAFT</option>
                    <option>PRE</option>
                    <option>OPEN</option>
                    <option>CLOSED</option>
                </select>
            </div>
        </div>
        <div class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">One Payment for Entire Event?</h3>
            <div class="editorContentContainer inputBox">
                <select @change="changeOnePayment" class="form-control">
                    <option style="display: none" :value="event.onePayment">{{event.onePayment}}</option>
                    <option>TRUE</option>
                    <option>FALSE</option>
                </select>
            </div>
            <div v-if="onePayment === 'TRUE'" class="form-check-inline input-box">
                <div class="form-heading">
                    Price of Event:
                </div>
                <label class="form-field">
                    <input class="form-control" type="number" @change="changeOnePaymentPrice" :value="event.onePaymentPrice"/>
                </label>
            </div>
        </div>
        <div class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Events:</h3>
            <b-card header="New Event">
                <div class="form-check-inline input-box">
                    <div class="form-heading">
                        Name:
                    </div>
                    <label class="form-field">
                        <input class="form-control" @change="createRaceName" :value="raceName"/>
                    </label>
                </div>
                <div class="form-check-inline input-box">
                    <div class="form-heading">
                        Type of Event:
                    </div>
                    <label class="form-field">
                        <input class="form-control" @change="createRaceDistance" :value="raceDistance"/>
                    </label>
                </div>
                <div class="form-check-inline input-box">
                    <div class="form-heading">
                        Time:
                    </div>
                    <label class="form-field">
                        <input class="form-control" @change="createRaceTime" :value="raceTime"/>
                    </label>
                </div>
                <div v-if="onePayment !== 'TRUE'" class="form-check-inline input-box">
                    <div class="form-heading">
                        Prices:
                    </div>
                    <label class="form-field">
                        <input class="form-control" type="number" @change="createRacePrice" :value="racePrice"/>
                    </label>
                </div>
                <button @click="createRace" class="createRace btn btn-block">
                    Create Event</button>
            </b-card>
            <b-card-group deck v-for="race in events" :key="race.name">
                <b-card :header="race.name">
                    <b-list-group>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Name:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeRaceName($event, race.name)" :value="race.name"/>
                            </div>
                        </div>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Type of Event:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeRaceDistance($event, race.name)" :value="race.distance"/>
                            </div>
                        </div>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Time:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeRaceTime($event, race.name)" :value="race.time"/>
                            </div>
                        </div>
                        <div v-if="onePayment !== 'TRUE'" class="form-check-inline input-box">
                            <div class="form-heading">
                                Price:
                            </div>
                            <div class="form-field">
                                <input class="form-control" type="number" @change="changeRacePrice($event, race.name)" :value="race.price"/>
                            </div>
                        </div>
                        <button class="remove btn btn-block btn-danger" @click="removeEvent(race.name)">Remove Event</button>
                    </b-list-group>
                </b-card>
            </b-card-group>
        </div>
        <div class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Clothing:</h3>
            <b-card header="New Clothing">
                <div class="form-check-inline input-box">
                    <div class="form-heading">
                        Name:
                    </div>
                    <label class="form-field">
                        <input class="form-control" @change="createClothingName" :value="clothingName"/>
                    </label>
                </div>
                <div class="form-check-inline input-box">
                    <div class="form-heading">
                        Price of clothing:
                    </div>
                    <label class="form-field">
                        <input class="form-control" type="number" @change="createClothingPrice" :value="clothingPrice"/>
                    </label>
                </div>
                <div class="form-check-inline input-box">
                    <div class="form-heading">
                        Clothing Image:
                    </div>
                    <div class="form-field">
                        <img class="imageUploadImage" :src="clothingImage" alt="Clothing Image"/>
                        <input class="uploadImage btn btn-success" type="file" @change="createClothingImage($event, clothingImage)">
                    </div>
                </div>
                <button @click="createClothing" class="createRace btn btn-block">
                    Add Clothing</button>
            </b-card>
            <b-card-group deck v-for="article in clothing" :key="article.name">
                <b-card :header="article.name">
                    <b-list-group>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Name:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeClothingName($event, article.name)" :value="article.name"/>
                            </div>
                        </div>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Price:
                            </div>
                            <div class="form-field">
                                <input class="form-control" type="number" @change="changeClothingPrice($event, article.name)" :value="article.price"/>
                            </div>
                        </div>
                        <div  v-if="article.img" class="form-check-inline input-box">
                            <div class="form-heading">
                                Clothing Image:
                            </div>
                            <div class="form-field">
                                <img class="imageUploadImage" :src="article.img"/>
                            </div>
                        </div>
                        <button class="remove btn btn-block btn-danger" @click="removeClothing(article.name)">Remove Clothing</button>
                    </b-list-group>
                </b-card>
            </b-card-group>
        </div>
        <button @click="createEvent" class="saveState btn btn-block btn-success">
            Create Event</button>
    </div>

</template>

<script>
    import PlainTextEditor from "../../../assets/PlainTextEditor";
    import EventRegistrationService from "../../../services/EventRegistrationService";
    import RichTextEditor from "../../../assets/RichTextEditor";
    import ImageUpload from "../../../assets/ImageUpload";
    import axios from "axios";
    import {backendURL} from '../../../environment';

    export default {
        name: "EventEditor",
        components: {ImageUpload, RichTextEditor, PlainTextEditor},
        data() {
            return {
                event: {},
                onePayment: "",
                onePaymentPrice: 0,
                events: [],
                clothing: [],
                raceName: "",
                raceDistance: "",
                racePrice: "",
                raceTime: "",
                clothingName: "",
                clothingPrice: "",
                clothingImage: "",
                clothingImageFile: {},
                pageImage: {},
                img: "",
            }
        },
        methods: {
            changeOnePaymentPrice() {
                this.event.onePaymentPrice = event.target.value;
            },
            changeOnePayment() {
                this.onePayment = event.target.value
            },
            createClothingName() {
                this.clothingName = event.target.value
            },
            createClothingPrice() {
                this.clothingPrice = event.target.value
            },
            createClothingImage(e) {
                if(window.confirm("Are you sure you want to upload this photo?")) {
                    const allowedTypes = ["image/jpeg", "image,jpg", "image/png"];
                    var files = e.target.files || e.dataTransfer.files;
                    const file = files[0];
                    if (!allowedTypes.includes(file.type)) {
                        alert("Wrong Type!");
                        return;
                    }
                    if (file.size > 500000) {
                        alert("File too large!");
                        return;
                    }
                    this.clothingImageFile = file;
                    let formData = new FormData();
                    formData.append('file', this.clothingImageFile);
                    axios.post(backendURL + 'api/events/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(responso => {
                        alert("Success!");
                        this.clothingImage = (responso.data.location);
                    }).catch(() => {
                        alert("Something went wrong, please try again")
                    })
                }
            },
            createClothing() {
                if(this.clothingPrice === "" || this.clothingName === "") {
                    alert("Need to add Price and Name to clothing!")
                } else {
                    this.clothing.push({
                        price: this.clothingPrice,
                        name: this.clothingName,
                        img: this.clothingImage
                    })
                    this.clothingPrice = "";
                    this.clothingName = "";
                    this.clothingImage = ""
                }
            },
            changeClothingName(event, name) {
                this.clothing.forEach(e => {
                    if(e.name === name) {
                        e.name = event.target.value
                    }
                });
            },
            changeClothingPrice(event, name) {
                this.clothing.forEach(e => {
                    if(e.name === name) {
                        e.price = event.target.value
                    }
                });
            },
            removeClothing(name) {
                let i = 0;
                this.clothing.forEach(e => {
                    if(e.name === name) {
                        this.clothing.splice(i, 1);
                        return;
                    }
                    i++
                })
            },
            removeEvent(name) {
                let i = 0;
                this.events.forEach(e => {
                    if(e.name === name) {
                        this.events.splice(i, 1);
                        return;
                    }
                    i++
                })
            },
            fileChange(e) {
                if(window.confirm("Are you sure you want to upload Image?")) {
                    const allowedTypes = ["image/jpeg", "image,jpg", "image/png"];
                    var files = e.target.files || e.dataTransfer.files;
                    const file = files[0];
                    if (!allowedTypes.includes(file.type)) {
                        alert("Wrong Type!");
                        return;
                    }
                    if (file.size > 500000) {
                        alert("File too large!");
                        return;
                    }
                    this.pageImage = file;
                    let formData = new FormData();
                    formData.append('file', this.pageImage);
                    axios.post(backendURL + 'api/events/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(responso => {
                        this.img = (responso.data.location);
                        alert("Success!")
                    }).catch(() => {
                        alert("Something went wrong, please try again")
                    })
                }
            },
            changeEventName(event) {
                this.event.name = event.target.value
            },
            changeStatus(event) {
                this.event.status = event.target.value
            },
            createRaceName(event) {
                this.raceName = event.target.value
            },
            createRaceDistance(event) {
                this.raceDistance = event.target.value
            },
            createRaceTime(event) {
                this.raceTime = event.target.value
            },
            createRacePrice(event) {
                this.racePrice = event.target.value
            },
            createRace() {
                if(this.raceTime === '' || this.raceDistance === '' || this.raceName === '') {
                    alert("Event needs time, distance, name, and price in order to be created")
                } else if (window.confirm("Are you sure you want to create this event?")) {
                    let event = {
                        name: this.raceName,
                        distance: this.raceDistance,
                        time: this.raceTime,
                        price: this.racePrice
                    };
                    this.events.push(event);
                    this.raceName = "";
                    this.raceDistance = "";
                    this.raceTime = "";
                    this.racePrice = "";

                }
            },
            changeRaceDistance(event, name) {
                this.events.forEach(e => {
                    if(e.name === name) {
                        e.distance = event.target.value
                    }
                });
            },
            changeRaceTime(event, name) {
                this.events.forEach(e => {
                    if(e.name === name) {
                        e.time = event.target.value
                    }
                });
            },
            changeRacePrice(event, name) {
                this.events.forEach(e => {
                    if(e.name === name) {
                        e.price = event.target.value
                    }
                });
            },
            changeRaceName(event, name) {
                this.events.forEach(e => {
                    if(e.name === name) {
                        e.name = event.target.value
                    }
                });
            },
            changeLocation(event) {
                this.event.location = event.target.value;
            },
            changeDate(event) {
                this.event.date = event.target.value;
            },
            changeRichText(event) {
                this.event.richText = event.getHTML()
            },
            isEmpty(obj) {
                for(let prop in obj) {
                    if(Object.prototype.hasOwnProperty.call(obj, prop)) {
                        return false;
                    }
                    return true;
                }
            },
            createEvent() {
                if(this.isEmpty(this.event) || this.event.name === "" || this.events.length === 0 || this.onePayment === ""
                    || this.event.status === "" || this.event.location === "" || this.event.date === "") {
                    alert("Event must have a name, status, events, location, One Payment Selection, and date")
                } else if(window.confirm("Are you sure you want to create event?")) {
                    this.event.events = this.events;
                    this.event.clothing = this.clothing;
                    this.event.img = this.img;
                    this.event.onePayment = this.onePayment;
                    this.event.type = "RACE";
                    this.eventService.createEvent(this.$cookies.get('user'), this.event).then((event) =>{
                        alert(event.name + " has been created!")
                    })
                }
            }
        },
        mounted() {
            this.eventService = EventRegistrationService.getInstance();
        }
    }
</script>

<style scoped>
    .input-box {
        width: 60%;
        margin-top: 1%;
        margin-left: 2%;
    }
    .imageUploadImage {
        width: 300px;
        height: 300px;
        margin-left: 1%;
        margin-top: 1%;
    }

    .remove {
        width: 50%;
        margin-top: 2%;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 500px) {

        .form-heading {
            width: 40%;
        }

        .form-field {
            width: 100%;
        }

        .input-box {
            width: 100%;
        }
    }

    @media (max-width: 450px) {
        .imageUploadImage {
            width: 200px;
            height: 200px;
            margin: 1%;
        }
    }

    @media (max-width: 350px) {
        .imageUploadImage {
            width: 150px;
            height: 150px;
            margin: 1%;
        }
    }

    @media (min-width: 1200px) {
        .chooseImage {
            width: 25%;
        }

        .uploadImage {
            width: 25%;
        }
    }

    .chooseImage {
        margin-top: 1%;
        width: 50%;
    }
    .uploadImage {
        margin-top: 1%;
        margin-bottom: 1%;
        width: 50%;
    }
    .saveState {
        margin-bottom: 2%;
    }
    .createRace {
        background-color: navy;
        color: white;
        margin-bottom: 2%;
    }
    .form-heading {
        margin-right: 2%;
        width: 20%;
    }
    .form-field {
        width: 80%;
    }
    .allCards {
        background-color: firebrick;
        margin-top: 2%;
        color: white;
        margin-right: 2%;
    }
    .events {
        text-align: center;
    }
</style>
