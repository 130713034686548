<template>
    <div class="textAreaContainer">
        <h3>{{heading}}</h3>
        <textarea @change="parentMethod" class="textArea" name="message" :value="value"></textarea>
    </div>
</template>

<script>
    export default {
        name: "TextAreaEditor",
        props: {
            heading: String,
            parentMethod: {type: Function},
            value: String
        }
    }
</script>

<style scoped>
    .textAreaContainer {
        margin-left: auto;
        margin-top: 2%;
        margin-right: auto;
        width: 100%;
        height: 200px;
    }
    .textArea {
        width: 100%;
        height: 80%;
    }
    @media (max-width: 700px) {
        .textAreaContainer {
            width: 90%;
        }
    }
</style>