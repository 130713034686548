<template>
    <div class="form-check-inline dropdownContainer">
        <div class="dropdownHeader">{{heading}}</div>
        <select @change="parentMethod"
                class="inputBox form-control">
            <option selected :value="value">{{value}}</option>
            <option v-for="element in elements" :key="element" :value="element">{{element.charAt(0) + element.slice(1).toLowerCase()}}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "DropdownForm",
        props: {
            heading: String,
            value: String,
            elements: {type: Array},
            parentMethod: {type: Function}
        }
    }
</script>

<style scoped>
    .dropdownContainer {
        margin-top: 1%;
        width: 60%;
    }
    .dropdownHeader {
        width: 20%;
        margin-right: 2%;
        color: dodgerblue;
    }
    .inputBox {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 700px) {
        .dropdownContainer {
            margin-top: 1%;
            width: 90%;
        }
        .dropdownHeader {
            width: 40%;
            margin-right: 2%;
        }
        .inputBox {
            width: 60%;
        }
    }
</style>