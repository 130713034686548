import {backendURL} from '../environment';
let API_URL = backendURL + 'api/';

export default class CardService {
    static myInstance = null;

    static getInstance() {
        if (CardService.myInstance == null) {
            CardService.myInstance = new CardService();
        }
        return this.myInstance
    }

    findPPKey = () =>
        fetch(API_URL + "seacret/pp", {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

}
