import {backendURL} from '../environment';

let API_URL = backendURL + 'api/users/';

export default class MemberService {
    static myInstance = null;

    static getInstance() {
        if (MemberService.myInstance == null) {
            MemberService.myInstance = new MemberService();
        }
        return this.myInstance
    }

    generatePasswordToken = (email) => {
        return fetch(API_URL + "generateResetToken", {
            method: 'put',
            body: JSON.stringify(email),
            headers: {
                'content-type': 'application/json'
            }
        }).then(response =>
            response.json());
    };

    getResetToken = (token) =>
        fetch(API_URL + "resetToken/" + token , {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

    changePassword = (token, password) => {
        return fetch(API_URL + "password/" + token, {
            method: 'put',
            body: JSON.stringify(password),
            headers: {
                'content-type': 'application/json'
            }
        }).then(response =>
            response.json());
    };


    findAllNewMembers = (token) =>
        fetch(API_URL + "newMembers", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());

    currentUser = (token) =>
        fetch(API_URL + "current", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response => response.json());

    findAllMembers = (token) =>
        fetch(API_URL + "members", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());

    findMemberByName = (token, name) =>
        fetch(API_URL + 'member/' + name, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());

    acceptMember = (token, memberId, member) => {
        return fetch(API_URL + "member/accept/" + memberId, {
            method: 'put',
            body: JSON.stringify(member),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());

    };

    promote = (token, memberId, member) => {
        return fetch(API_URL + "member/promote/" + memberId, {
            method: 'put',
            body: JSON.stringify(member),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    demote = (token, memberId, member) => {
        return fetch(API_URL + "member/demote/" + memberId, {
            method: 'put',
            body: JSON.stringify(member),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    addCardToWebsite = (token, useId, user) => {
        return fetch(API_URL + "member/athleteWebsite/" + useId, {
            method: 'put',
            body: JSON.stringify(user),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    removeCardFromWebsite = (token, memberName) => {
        return fetch(API_URL + "member/athleteWebsite/" + memberName, {
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    updateImage = (token, memberId, img) => {
        return fetch(API_URL + "member/image/" + memberId, {
            method: 'put',
            body: JSON.stringify(img),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    renew = (token) => {
        return fetch(API_URL + "member/renew", {
            method: 'put',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    }

    renewMembership = (token, member) => {
        return fetch(API_URL + "member/renewMembership/" + member._id, {
            method: 'put',
            body: JSON.stringify(member),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    update = (token, memberId, member) => {
        return fetch(API_URL + "member/update/" + memberId, {
            method: 'put',
            body: JSON.stringify(member),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    declineMember = (token, memberId) => {
        return fetch(API_URL + "member/remove/" + memberId, {
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };
}
