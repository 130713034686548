import {backendURL} from '../environment';

let API_URL = backendURL + 'api/';

export default class PageService {
    static myInstance = null;

    static getInstance() {
        if (PageService.myInstance == null) {
            PageService.myInstance = new PageService();
        }
        return this.myInstance
    }

    findAllPages = () =>
        fetch(API_URL + "pages", {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

    findPageByName = (pageName) =>
        fetch(API_URL + "pages/name/" + pageName, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

    findPageById = (id) =>
        fetch(API_URL + 'page/' + id)
            .then(response => response.json());

    createPage = (token, page) => {
        return fetch(API_URL + "pages", {
            method: 'post',
            body: JSON.stringify(page),
            headers: {
                'content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json())
            .catch(() => alert("Page Not Be Created"));
    };

    updatePage = (token, pageId, page) => {
        return fetch(API_URL + "pages/" + pageId, {
            method: 'put',
            body: JSON.stringify(page),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    uploadImage = (image) => {
        return fetch(API_URL + "pages/image", {
            method: 'post',
            body: image,
            headers: {
                'Content-Type': image.type
            }
        }).then(response =>
            response.json());
    };

    deletePage = (token, pageId) => {
        return fetch(API_URL + "pages/" + pageId, {
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    deleteCard = (cardId) => {
        return fetch(API_URL + 'pages/card/' + cardId, {
            method: "delete",
            header: {
                'content-type': 'application/json'
            }
        })
    };
}
