import Login from "./components/Pages/Login"
import Home from './components/Pages/Home'
import Register from "./components/Pages/Register";
import Page from "./components/Pages/Page";
import Event from "./components/events/Event";
import EventForm from "./components/events/EventForm";
import EventPayment from "./components/events/EventPaymentPage"
import EventConfirmation from "./components/events/EventConfirmation";
import Editor from "./components/editor/Editor";
import NavElementEditor from "./components/editor/navElements/NavElementEditor";
import NewNavElementEditor from "./components/editor/navElements/NewNavElementEditor";
import CardEditor from "./components/editor/cards/CardEditor";
import NewCardEditor from "./components/editor/cards/NewCardEditor";
import PageEditor from "./components/editor/pages/PageEditor";
import NewPageEditor from "./components/editor/pages/NewPageEditor";
import NewEventEditor from "./components/editor/events/NewEventEditor";
import EventEditor from "./components/editor/events/EventEditor";
import HomePageEditor from "./components/editor/homePage/HomePageEditor";
import NewMember from "./components/editor/newMember/NewMember";
import Members from "./components/editor/members/Members";
import GbtcStore from "./components/editor/gbtcStore/GbtcStore";
import GbtcRaceRegistration from "./components/editor/gbtcRaceRegistration/GbtcRaceRegistration";
import ProfileManagement from "./components/editor/profileManagement/ProfileManagement";
import Applicant from "./components/editor/newMember/Applicant";
import Cards from "./components/Pages/Cards";
import Donate from "./components/Pages/Donate";
import Donations from "./components/editor/donations/Donations";
import PasswordReset from "./components/Pages/PasswordReset";
import ForgotPassword from "./components/Pages/ForgotPassword";
import Member from "./components/editor/members/Member";
const routes =[
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: "/resetPassword/:token",
        name: "ResetPassword",
        component: PasswordReset
    },
    {
        path: "/forgotPassword",
        name: "Forgot Password",
        component: ForgotPassword
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/page/:name',
        component: Page,
        props: true
    },
    {
        path: '/event/:name',
        name: 'Event',
        component: Event
    },
    {
        path: '/event/registration/:name',
        name: 'EventRegistration',
        component: EventForm
    },
    {
        path: '/articles',
        name: "ArticleCards",
        component: Cards
    },
    {
        path: '/event/payment/:name',
        name: 'EventPayment',
        props: true,
        component: EventPayment
    },
    {
        path: '/donate',
        name: "Donate",
        component: Donate
    },
    {
        path: '/event/confirmation/:name',
        name: 'EventConfirmation',
        props: true,
        component: EventConfirmation
    },
    { path: '/members', component: Editor,
        children: [
            {
                path: 'donations',
                component: Donations
            },
            {
                path: 'navElementEditor',
                component: NavElementEditor
            },
            {
                path: 'newMembers',
                component: NewMember,
                children: [{
                    path: ':name',
                    component: Applicant
                }]
            },
            {
                path: 'members',
                component: Members,
                children: [{
                    path: ':name',
                    component: Member
                }]
            },
            {
                path: 'gbtcStore',
                component: GbtcStore
            },
            {
                path: 'gbtcRaceReg',
                component: GbtcRaceRegistration
            },
            {
                path: 'profile',
                component: ProfileManagement
            },
            {
                path: 'newNavElementEditor',
                component: NewNavElementEditor
            },
            {
                path: 'cardEditor',
                component: CardEditor
            },
            {
                path: 'newCardEditor',
                component: NewCardEditor
            },
            {
                path: 'pageEditor',
                component: PageEditor
            },
            {
                path: 'newPageEditor',
                component: NewPageEditor
            },
            {
                path: 'eventEditor',
                component: EventEditor
            },
            {
                path: 'newEventEditor',
                component: NewEventEditor
            },
            {
                path: 'homePageEditor',
                component: HomePageEditor
            },
        ]
    }

];

export default routes;
