<template>
    <div class="passwordResetContainer">
        <h1 class="passwordResetTitle">Forgot Password</h1>
        <div class="confirmPasswordContainer">
            <div class="inputContainer">
                <h3 class="inputHeading">Email</h3>
                <input class="form-control" type="email" :value="user.email" placeholder="email" @change="changeEmail"/>
            </div>
            <div class="buttonContainer">
                <button class="btn btn-block btn-success" @click="submitPassword">Request new password</button>
            </div>
        </div>
    </div>
</template>

<script>
    import MemberService from "../../services/memberService";

    export default {
        name: "ForgotPassword",
        data() {
            return {
                user: {
                    email: ""
                }
            }
        },
        methods: {
            changeEmail(e) {
                this.user.email = e.target.value
            },
            submitPassword() {
                if(window.confirm("Are you sure you want to reset your password?")) {
                    this.memberService.generatePasswordToken(this.user).then(() => {
                        alert("Email sent with instructions to reset password!");
                        this.$router.push('/')
                    }).catch(() => {
                        alert("Email not found in databse!")
                    })
                }
            }
        },
        mounted() {
            this.memberService = MemberService.getInstance();
        }
    }
</script>

<style scoped>
    .passwordResetContainer {
        text-align: center;
    }

    .passwordResetTitle {
        font-size: 25px;
        color: red;
        font-weight: bolder;
        font-style: italic;
    }
    .inputContainer {
        display: inline-block;
        width: 60%;
    }
    .inputHeading {
        display: inline-block;
        margin-top: 5%;
        width: 30%;
    }
    .form-control {
        display: inline-block;
        margin-left: 5%;
        width: 65%;
    }
    .buttonContainer {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5%;
    }

    @media (max-width: 700px)  {
        .inputContainer {
            width: 80%;
        }

    }
    @media (max-width: 600px)  {
        .inputContainer {
            width: 100%;
        }
        .inputHeading {
            font-size: 13px;
            width: 40%;
        }

        .form-control {
            display: inline-block;
            margin-left: 2%;
            margin-right: 2%;
            width: 56%;
        }

    }

</style>