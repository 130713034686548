<template>
    <div class="banner-image-header">
        <img class="bannerImage" :src="bannerImg" alt="Center image"/>
        <h1 class="bannerHeader">{{bannerTitle}}</h1>
    </div>
</template>

<script>
    export default {
        name: "BannerImage",
        props: ['bannerTitle', 'bannerImg']
    }
</script>

<style scoped>
    .banner-image-header {
    }
    .bannerHeader {
        top: 50%;
        text-align: center;
        color: white;
        border-radius: 5%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2%;
        width: 100%;
        -webkit-text-stroke: 1px black;
        font-weight: bold;
        font-size: 60px;
        background: rgba(255,0,0, 0.9);
    }
    .bannerImage {
        object-fit: contain;
        height: 700px;
        border-radius: 10%;
        border: 4px solid red;
        -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 5s; /* Firefox < 16 */
        -ms-animation: fadein 5s; /* Internet Explorer */
        -o-animation: fadein 5s; /* Opera < 12.1 */
        animation: fadein 5s;
        width: 100%;
    }
    @media (max-width: 600px) {
        .bannerImage {
            height: 450px
        }
    }
    @media (max-width: 500px) {
        .bannerImage {
            height: 400px
        }
    }
</style>
