<template>
    <div class="profileContainer">
        <div class="editProfileContainer border border-dark">
            <h1 class="editorHeader">
                My Profile
            </h1>
            <div v-if="user.type === 'ADMIN' || user.type === 'MEMBER'" class="websiteContainer d-inline-flex" :key="user.websiteDisplay">
                <div class="websiteText">
                    Do you want to be featured on our website?
                </div>
                <div></div>
                <font-awesome-icon @click="websiteDisplayToggle" v-if="user.websiteDisplay === 'TRUE'" class="toggleIcon" :icon="['fas', 'toggle-on']" size="2x" />
                <font-awesome-icon @click="websiteDisplayToggle" v-else :key="user.websiteDisplay" class="toggleIcon" :icon="['fas', 'toggle-off']" size="2x" />
            </div>
            <StandardForm :parent-method="changeName" :val="user.name" heading="First and Last Name"/>
            <StandardForm :parent-method="changeEmail" :val="user.email" heading="Enter Email"/>
            <DropdownForm :parent-method="changeGender" :value="user.gender" heading="Gender" :elements="genders" :multiple="false"/>
            <DropdownForm :parent-method="changeAgeGroup" :value="user.ageGroup" heading="Age Group" :elements="ageGroups" :multiple="false"/>
            <div class="eventContainer editorContentContainer border border-dark">
                <h3 class="editorContentHeader">Events</h3>
                <div class="btn-group" v-for="event in user.events" :key="event">
                    <input type="text" class="form-control" :value="event" readonly/>
                    <span @click="deleteEvent(event)" class="cardClear fa fa-times">
                        <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                    </span>
                </div>
                <select @change="selectEvent" class="inputBox form-control">
                    <option style="display: none" selected="main">---</option>
                    <option v-for="event in events" :key="event" :value="event">{{event.charAt(0) + event.slice(1).toLowerCase()}}</option>
                </select>
                <button @click="addEvent" class="addDropdown btn btn-block">Add Dropdown Page</button>
            </div>
            <TextAreaEditor :parent-method="changeBio" heading="Your Bio: " :value="user.bio"/>
            <button type="submit" @click="onSubmit" class="btn btn-success registerButton">Update Profile</button>
        </div>
        <div v-if="user.type === 'ADMIN' || user.type === 'MEMBER'">
            <ImageUpload :image="profileImage" :parent-file-change="fileChange"/>
        </div>
        <div v-if="this.user.type !== 'NONMEMBER' && isMounted" class="eventPaymentContainer">
            <h3>Renew Membership by: {{paymentDueString}}</h3>
            <Payment :credentials="client" :payment="totalDue" :description="description" :cancelled="cancelled" :authorized="authorized"></Payment>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import MemberService from "../../../services/memberService";
    import StandardForm from "../../../assets/StandardForm";
    import DropdownForm from "../../../assets/DropdownForm";
    import TextAreaEditor from "../../../assets/TextAreaEditor";
    import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
    import ApiService from "../../../services/apiService";
    import Payment from "../../../assets/Payment";
    import axios from "axios";
    import ImageUpload from "../../../assets/ImageUpload";
    import {backendURL} from '../../../environment';

    export default {
        name: "ProfileManagement",
        components: {ImageUpload, Payment, TextAreaEditor, DropdownForm, StandardForm},
        data() {
            return {
                user: {
                    events: [],
                    bio: "",
                    websiteDisplay: "FALSE"
                },
                profileImage: "",
                totalDue: "",
                description: "",
                filledOut: false,
                isMounted: false,
                paymentDueDate: new Date(),
                genders: ["MALE", "FEMALE", "NONBINARY"],
                client: {},
                ageGroups: ["OPEN", "MASTERS"],
                events: ["", "MARATHON", "ROAD RUNNER", "XC", "TRACK", "FIELD"]
            }
        },
        computed: {
            timesIcon(){
                return faTimes
            },
            renderPay() {
                return this.isMounted
            },
            paymentDueString() {
                return this.paymentDueDate.toDateString()
            }
        },
        methods: {
            fileChange(e) {
                if(window.confirm("Are you sure you want to upload image?")) {
                    const allowedTypes = ["image/jpeg", "image,jpg", "image/png"];
                    var files = e.target.files || e.dataTransfer.files;
                    const file = files[0];
                    if (!allowedTypes.includes(file.type)) {
                        alert("Wrong Type!");
                        return;
                    }
                    if (file.size > 500000) {
                        alert("File too large!");
                        return;
                    }
                    let formData = new FormData();
                    formData.append('file', file);
                    axios.post(backendURL + 'api/users/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response) => {
                        this.profileImage = response.data.location;
                        this.user.img = response.data.location;
                        this.memberService.updateImage(Vue.$cookies.get('user'), this.user._id, {img: response.data.location}).then(() => {
                            alert("Success");
                        }).catch(() => alert("Something went wrong please try again!"))
                    }).catch(() => {
                        alert("Please Try Again")
                    })
                }
            },
            websiteDisplayToggle() {
                if(this.user.websiteDisplay === "TRUE") {
                    if(window.confirm("Are you sure you want to remove your card from the website?")) {
                        this.user.websiteDisplay = "FALSE";
                        this.memberService.removeCardFromWebsite(Vue.$cookies.get('user'), this.user.name).then(() => {
                            alert("Success!")
                        }).catch(() => {
                            this.user.websiteDisplay = "TRUE";
                            alert("Something went wrong, please try again.")
                        });
                    }
                } else {
                    if(window.confirm("By putting yourself on the website you agree to sharing: Your name, gender, age group, profile picture," +
                        "bio, and event groups. Do you wish to proceed?")) {
                        if(this.profileImage !== "") {
                            let athleteCard = {};
                            this.user.websiteDisplay = "TRUE";
                            athleteCard.eventGroup = this.user.events;
                            athleteCard.gender = this.user.gender;
                            athleteCard.ageGroup = this.user.ageGroup;
                            athleteCard.content = this.user.bio;
                            athleteCard.name = this.user.name;
                            athleteCard.img = this.profileImage;
                            this.memberService.addCardToWebsite(Vue.$cookies.get('user'), this.user._id, this.user).then(() => {
                                alert("Success! Your athlete card is awaiting admin approval!")
                            }).catch(() => {
                                alert("Something went wrong, please try again")
                                this.user.websiteDisplay = "FALSE"
                            })
                        } else {
                            alert("Profile image not supplied! Please either refresh page (if you just uploaded one) or try again!")
                        }
                    }
                }
            },
            onSubmit() {
                if (this.filledOut) {
                    if(window.confirm("Are you sure you want to update your profile?")) {
                        this.memberService.update(Vue.$cookies.get('user'), this.user._id, this.user).then(() => {
                            this.memberService.currentUser(Vue.$cookies.get('user')).then((response) => {
                                this.user = response.user;
                                alert("Success!")
                            }).catch(() => {
                                alert("Something went wrong, please refresh the page")
                            })
                        }).catch(() => {
                            alert("Something went wrong, please try again.")
                        })
                    }
                } else {
                    alert("Not Filled Out!")
                }
            },
            addEvent() {
                let added = false;
                this.user.events.forEach(event => {
                    if(event === this.event) {
                        added = true
                    }
                });
                if(added) {
                    alert("Already Added")
                } else {
                    this.user.events.push(this.event)
                }
                this.checkFill()
            },
            selectEvent() {
                this.event = event.target.value
            },
            deleteEvent(event) {
                let i =0;
                this.user.events.forEach(e => {
                    if(e === event) {
                        this.user.events.splice(i, 1)
                    }
                    i++
                });
                this.checkFill()
            },
            changeGender(event) {
                this.user.gender = event.target.value;
                this.checkFill()
            },
            changeName(event) {
                this.user.name = event.target.value;
                this.checkFill()
            },
            changeEmail(event) {
                this.user.email = event.target.value;
                this.checkFill()
            },
            changeAgeGroup(event) {
                this.user.ageGroup = event.target.value;
                this.checkFill()
            },
            changeBio(event) {
                this.user.bio = event.target.value;
                this.checkFill()
            },
            authorized() {
                this.memberService.renew(Vue.$cookies.get('user')).then(() => {
                    this.memberService.currentUser(Vue.$cookies.get('user')).then((response) => {
                        this.user = response.user;
                        this.paymentDueDate = new Date(this.user.membershipDuration);
                    });
                }).catch(() => {
                    alert("Something went wrong, please contact a board member");
                    this.memberService.currentUser(Vue.$cookies.get('user')).then((response) => {
                        this.user = response.user
                    });
                })
            },
            cancelled() {
                alert("Transaction Cancelled")
            },
            checkFill() {
                this.filledOut = !!(this.user.name && this.user.email && this.user.bio !== "" && this.user.ageGroup
                    && this.user.gender && this.user.events.length !== 0);
            }
        },
        mounted() {
            this.memberService = MemberService.getInstance();
            this.memberService.currentUser(Vue.$cookies.get('user')).then((response) => {
                this.user = response.user;
                this.profileImage = response.user.img;
                this.paymentDueDate = new Date(this.user.membershipDuration);
                if(this.user.type === 'NONMEMBER') {
                    this.description = "First Time Membership Dues"
                    this.totalDue = "90"
                } else {
                    this.description = "Renewal Membership Dues"
                    this.totalDue = "75"
                }
                // if(this.paymentDueDate < new Date()) {
                //     this.totalDue = "90"
                // } else {
                //     this.totalDue = "75"
                // }
            }).then(() => {
                let apiService = ApiService.getInstance();
                apiService.findPPKey().then(ppKey => {
                    this.client = ppKey;
                    this.isMounted = true
                });
            });

        }
    }
</script>

<style scoped>
    .websiteText {
        font-size: 14px;
        width: 70%;
    }
    .websiteContainer {
        width: 50%;
        margin: 1% auto;
        text-align: center;
    }
    .toggleIcon {
        width: 30%;
    }
    .profileContainer {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .editProfileContainer {
        padding-bottom: 2%;
    }
    .textAreaContainer {
        width: 60%;
    }
    .imageUploadContainer {
        margin-top: 4%;
        width: 60%;
        text-align: left;
        padding-bottom: 2%;
        margin-left: auto;
        margin-right: auto;
    }

    .eventContainer {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    .addDropdown {
        background-color: navy;
        color: white;
        margin: auto auto 1%;
        width: 90%;
    }
    .form-control {
        margin-left: 2%;
    }
    .inputBox {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .cardClear {
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        height: 14px;
        margin-left: auto;
        font-size: 14px;
        cursor: pointer;
        color: #ccc;
    }
    .registerButton {
        width: 60%;
        margin-top: 2%;
    }
    .eventPaymentContainer{
        margin-top: 2%;
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }

    @media (max-width: 700px) {
        .websiteContainer {
            width: 90%;
        }
        .eventContainer {
            width: 90%;
        }
        .imageUploadContainer {
            width: 100%;
        }

    }

</style>