<template>
    <b-card class="payContainer">
        <div>
            <h3 class="total">Total: {{payment}}$</h3>
            <paypal-checkout   :amount="payment"
                               currency="USD"
                               locale="en_US"
                               :client="credentials"
                               :description="description"
                               @payment-completed="authorized"
                               @payment-cancelled="cancelled">

            </paypal-checkout>
        </div>
    </b-card>
</template>

<script>

    export default {
        name: "PaymentComponent",
        props: ['payment', 'authorized', 'cancelled', 'credentials', 'description'],

    }
</script>

<style scoped>
    .total {
        font-weight: bolder;
        margin-bottom: 3%;
    }
</style>