<template>
    <b-card class="confirmationContainer">
        <h1 class="thankYouHeader">
            Thank you {{registrant.firstName}} for signing up for the {{eventName}}!
        </h1>
        <b-card-text class="eventsContainer">
            <b>Event(s) signed up for: </b>
            <div v-for="event in registrant.event" :key="event.name">{{event.name}}</div>
        </b-card-text>
        <router-link :to="`../registration/${eventName}`">
            <b-button variant="primary">Register another Person</b-button>
        </router-link>
    </b-card>
</template>

<script>

    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "EventConfirmation",
        data() {
            return {
                eventName: "",
                registrant: {}
            }
        },
        methods: {
            ...mapActions([
                'editRegistrant'
            ]),
            checkStatus() {
                if(this.registrant.firstName && this.registrant.lastName && this.registrant.email
                    && this.registrant.division && this.registrant.dob && this.registrant.phone
                    && this.registrant.country && this.registrant.zip && this.registrant.city && this.registrant.state
                    && this.registrant.emergencyContact && this.registrant.relationship
                    && this.registrant.theirPhone) {
                    return true
                }
            },
        },
        computed: {
            ...mapGetters([
                'getRegistrant'
            ]),
        },
        mounted() {
            this.registrant = this.getRegistrant;
            this.eventName = this.$route.params.name;
            this.registrant = this.getRegistrant;
            if(!this.checkStatus()) {
                this.$router.push({path: "../registration/" + this.$route.params.name})
                this.editRegistrant({})
            }
        }

    }
</script>

<style scoped>
    .confirmationContainer {
        text-align: center;
    }
    .eventsContainer {
        font-size: 15px;
        margin-top: 2%;
    }
</style>