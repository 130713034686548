<template>
    <div class="eventContainer" :key="selectedEvent._id">
        <h1 class="editorHeader">Event Manager</h1>
        <div class="events">
            <button v-for="event in events" :key="event._id" @click="changeEvent(event)" class="allCards btn">
                {{event.name}}
            </button>
        </div>
        <div>
            {{eventName}}
        </div>
        <PlainTextEditor heading="Event Name" :val="selectedEvent.name" :parentMethod="changeEventName"/>
        <ImageUpload :image="img" :parent-file-change="fileChange"/>
        <PlainTextEditor heading="Location: " :val="selectedEvent.location" :parentMethod="changeLocation"/>
        <PlainTextEditor heading="Date: " type="date" :val="selectedEvent.date" :parentMethod="changeDate"/>
        <div class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Event Content</h3>
            <div class="richTextEditorContainer">
                <RichTextEditor :richText="selectedEvent.richText" :changeRichText="changeRichText"/>
            </div>
        </div>
        <div class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Event Status: {{selectedEvent.status}}</h3>
            <div class="editorContentContainer inputBox">
                <select @change="changeStatus"
                        class=" form-control">
                    <option style="display: none" :value="selectedEvent.status">{{selectedEvent.status}}</option>
                    <option>DRAFT</option>
                    <option>PRE</option>
                    <option>OPEN</option>
                    <option>CLOSED</option>
                    <option>EXTERNAL</option>
                </select>
            </div>
        </div>
        <PlainTextEditor v-if="selectedEvent.onePayment === 'TRUE'" type="number" :val="selectedEvent.onePaymentPrice.toString()"
                         heading="One Payment Price" :parent-method="changeOnePaymentPrice"/>
        <div class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Events:</h3>
            <b-card-group deck v-for="event in selectedEvent.events" :key="event.name" class="eventInfoContainer">
                <b-card :header="event.name">
                    <b-list-group>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Name:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeRaceName($event, event.name)" :value="event.name"/>
                            </div>
                        </div>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Type of Event:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeRaceDistance($event, event.name)" :value="event.distance"/>
                            </div>
                        </div>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Time:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeRaceTime($event, event.name)" :value="event.time"/>
                            </div>
                        </div>
                        <div class="form-check-inline input-box" v-if="selectedEvent.onePayment !== 'TRUE'">
                            <div class="form-heading">
                                Price:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeRacePrice($event, event.name)" :value="event.price"/>
                            </div>
                        </div>
                    </b-list-group>
                </b-card>
            </b-card-group>
        </div>
        <div class="editorContentContainer border border-dark">
            <h3 class="editorContentHeader">Clothing:</h3>
            <b-card-group deck v-for="c in selectedEvent.clothing" :key="c.name">
                <b-card :header="c.name">
                    <b-list-group>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Name:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeRaceName($event, c.name)" :value="c.name"/>
                            </div>
                        </div>
                        <div class="form-check-inline input-box">
                            <div class="form-heading">
                                Type of Event:
                            </div>
                            <div class="form-field">
                                <input class="form-control" @change="changeRaceDistance($event, c.name)" :value="c.price"/>
                            </div>
                        </div>
                        <img class="imageUploadImage" :src="c.img"/>
                    </b-list-group>
                </b-card>
            </b-card-group>
        </div>

        <export-excel :data="exportedRegistrants"
                      class="btn btn-primary raceData"
                      worksheet = "Race Worksheet"
                      :fields="fields"
                      type="csv"
                      name="race_data.xls"/>

        <button @click="saveEvent" class="saveState btn btn-block btn-success">
            Save Event</button>
        <button @click="deleteEvent" class="deleteButton btn btn-block btn-danger">
            Delete Event</button>
    </div>

</template>

<script>
    import PlainTextEditor from "../../../assets/PlainTextEditor";
    import EventRegistrationService from "../../../services/EventRegistrationService";
    import RichTextEditor from "../../../assets/RichTextEditor";
    import ImageUpload from "../../../assets/ImageUpload";
    import axios from "axios";
    import {backendURL} from '../../../environment';

    export default {
        name: "EventEditor",
        components: {ImageUpload, RichTextEditor, PlainTextEditor},
        data() {
            return {
                events: [],
                selectedEvent: {},
                eventName: "",
                exportedRegistrants: [],
                img: "",
                fields: {}
            }
        },
        methods: {
            changeOnePaymentPrice() {
                this.selectedEvent.onePaymentPrice = event.target.value;
            },
            fileChange(e) {
                if(window.confirm("Are you sure you want to upload image?")) {
                    const allowedTypes = ["image/jpeg", "image,jpg", "image/png"];
                    var files = e.target.files || e.dataTransfer.files;
                    const file = files[0];
                    if (!allowedTypes.includes(file.type)) {
                        alert("Wrong Type!");
                        return;
                    }
                    if (file.size > 500000) {
                        alert("File too large!");
                        return;
                    }
                    let formData = new FormData();
                    formData.append('file', file);
                    axios.post(backendURL + 'api/events/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response) => {
                        this.selectedEvent.img = response.data.location;
                        this.img = response.data.location;
                        this.eventService.updateEvent(this.$cookies.get('user'), this.selectedEvent._id, this.selectedEvent).then(() => {
                            alert("Success");
                        }).catch(() => alert("Something went wrong please try again!"))
                    }).catch(() => {
                        alert("Please Try Again")
                    })
                }
            },
            changeEventName(event) {
                this.selectedEvent.name = event.target.value
            },
            changeStatus(event) {
                this.selectedEvent.status = event.target.value
            },
            changeRaceDistance(event, name) {
                this.selectedEvent.events.forEach(e => {
                    if(e.name === name) {
                        e.distance = event.target.value
                    }
                });
            },
            changeRaceTime(event, name) {
                this.selectedEvent.events.forEach(e => {
                    if(e.name === name) {
                        e.time = event.target.value
                    }
                });
            },
            changeRacePrice(event, name) {
                this.selectedEvent.events.forEach(e => {
                    if(e.name === name) {
                        e.price = event.target.value
                    }
                });
            },
            changeRaceName(event, name) {
                this.selectedEvent.events.forEach(e => {
                    if(e.name === name) {
                        e.name = event.target.value
                    }
                });
            },
            changeEvent(event) {
                this.selectedEvent = event;
                this.img = event.img;
                this.renderExportedEvent(event.registrants, event.events, event.clothing);
                let myDate = new Date(event.date);
                let myDay = myDate.getUTCDate();
                let myMonth = myDate.getMonth();
                let myYear = myDate.getFullYear().toString();
                if(parseInt(myYear) < 10) {
                    myYear = '0' + myYear
                }
                if(parseInt(myYear) < 100) {
                    myYear = '0' + myYear
                }
                if(parseInt(myYear) < 1000) {
                    myYear = '2' + myYear
                }
                if(myDay < 10) {
                    myDay = '0' + myDay
                }
                if(myMonth++ < 10) {
                    myMonth = '0' + (myMonth++)
                }
                this.selectedEvent.date = '' + myYear + '-' + myMonth + '-' + myDay
            },
            changeLocation(event) {
                this.selectedEvent.location = event.target.value;
            },
            changeDate(event) {
                this.selectedEvent.date = event.target.value;
            },
            changeRichText(event) {
                this.selectedEvent.richText = event.getHTML()
            },
            saveEvent() {
                if(window.confirm("Are you sure you want to save element?")) {
                    this.eventService.updateEvent(this.$cookies.get('user'), this.selectedEvent._id, this.selectedEvent).then(() => {
                        alert("Success");
                        this.eventService.findAllEvents().then(events => {
                            this.renderEvents(events)
                        })
                    }).catch(() => alert("Something went wrong please try again!"))
                }
            },
            deleteEvent() {
                if(window.confirm("Are you sure you want to delete this element?")) {
                    this.eventService.deleteEvent(this.$cookies.get('user'), this.selectedEvent._id).then(() => {
                        alert("Success!");
                        this.eventService.findAllEvents().then(events => {
                            this.renderEvents(events)
                        })
                    }).catch(() =>
                        alert("Something went wrong, try again.")
                    )
                }
            },
            renderExportedEvent(registrants, events, clothing) {
                this.exportedRegistrants = registrants;
                this.exportedRegistrants.forEach(registrant => {
                    registrant.event.forEach(e => {
                        registrant[e.name] = "YES"
                    })
                });
                this.fields = {
                    "First Name": "firstName",
                    "Last Name": "lastName",
                    "Email": "email",
                    "Division": "division",
                    "DOB": "dob",
                    "Phone #": "phone",
                    "Country": "country",
                    "Zip": "zip",
                    "City": "city",
                    "State": "state",
                    "Emergency Contact": "emergencyContact",
                    "Contact Phone": "theirPhone",
                    "USATF Number": "usatfNumber",
                    "Team": "team",
                    "Shirt Size": "teeShirtSize",
                    "Total Due": "totalDue",
                    "Created at": "createdAt"
                };
                events.forEach(e => {
                    if (!Object.prototype.hasOwnProperty.call(this.fields, e.name)) {
                        this.fields[e.name] = e.name
                    }
                });
                clothing.forEach(e => {
                    if (!Object.prototype.hasOwnProperty.call(this.fields, e.name)) {
                        this.fields[e.name] = e.name
                    }
                });
            },
            renderEvents(events) {
                this.events = events;
                this.selectedEvent = events[0];
                this.img = events[0].img;
                let myDate = new Date(events[0].date);
                let myDay = myDate.getUTCDate();
                let myMonth = myDate.getMonth();
                let myYear = myDate.getFullYear().toString();
                this.renderExportedEvent(events[0].registrants, events[0].events, events[0].clothing);
                if(parseInt(myYear) < 10) {
                    myYear = '0' + myYear
                }
                if(parseInt(myYear) < 100) {
                    myYear = '0' + myYear
                }
                if(parseInt(myYear) < 1000) {
                    myYear = '2' + myYear
                }
                if(myDay < 10) {
                    myDay = '0' + myDay
                }
                myMonth++;
                if(myMonth < 10) {
                    myMonth = '0' + myMonth
                }
                this.selectedEvent.date = '' + myYear + '-' + myMonth + '-' + myDay
            }
        },
        mounted() {
            this.eventService = EventRegistrationService.getInstance();
            this.eventService.findAllEvents().then(events => {
                this.renderEvents(events)
            })
        }
    }
</script>

<style scoped>
    .input-box {
        width: 60%;
        margin-top: 1%;
    }
    .form-heading {
        margin-right: 2%;
        width: 20%;
    }
    .form-field {
        width: 80%;
    }
    .raceData {
        width: 100%;
        margin-top: 2%;
    }
    .allCards {
        background-color: firebrick;
        margin-top: 2%;
        color: white;
        margin-right: 2%;
    }
    .events {
        text-align: center;
    }
    .imageUploadImage {
        width: 300px;
        height: 300px;
        margin-left: 1%;
        margin-top: 1%;
    }
    .eventInfoContainer {
        width: 100%;
    }

    @media (max-width: 500px) {
        .imageUploadImage {
            width: 200px;
            height: 200px;
        }
        .form-heading {
            width: 40%;
        }
        .form-field {
            width: 100%;
        }
        .input-box {
            width: 100%;
        }
    }
</style>
