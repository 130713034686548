<template>
    <div class="cardEditorContainer">
        <h1 class="editorHeader">Card Editor</h1>
        <select @change="filterCards" id="filterPages"
                class="inputBox form-control">
            <option style="display: none" :value="this.status">{{this.status}}</option>
            <option>ATHLETE</option>
            <option>COACH</option>
        </select>
        <div class="cards">
            <button v-for="card in cards" :key="card._id" @click="changeCard(card)" class="allCards btn">
                {{card.name}}
            </button>
        </div>
        <div class="editorContentContainer border border-dark">
            <h3  class="editorContentHeader">Name of Card</h3>
            <div class="inputBox">
                <input class="form-control"
                       :value="currentCardTitle"
                       @change="cardNameChange"/>
            </div>
        </div>
        <div v-if="currentCardType === 'ATHLETE'">
            <div class="editorContentContainer border border-dark">
                <h3 class="editorContentHeader">Event Groups</h3>
                <div v-for="event in currentCard.eventGroup" :key="event" class="groupOfCards btn-group">
                    <input type="text" class="cardsForPage form-control" :value="event" readonly/>
                    <span @click="removeEvent(event)" class="cardClear fa fa-times">
                        <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                    </span>
                </div>
                <select @change="selectEvent" class="inputBox form-control">
                    <option style="display: none" selected="main">---</option>
                    <option v-for="event in eventGroup" :key="event" :value="event">{{event}}</option>
                </select>
                <button @click="addEvent" class="addDropdown btn btn-block">Add Card</button>
            </div>
            <div class="editorContentContainer border border-dark">
                <h3 class="editorContentHeader">Gender: {{currentCard.gender}}</h3>
                <select @change="changeGender" class="inputBox form-control">
                    <option style="display: none" selected="main">{{currentCard.gender}}</option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHER">Other</option>
                </select>
            </div>
            <div class="editorContentContainer border border-dark">
                <h3 class="editorContentHeader">Division: {{currentCard.ageGroup}}</h3>
                <select @change="changeDivision" class="inputBox form-control">
                    <option style="display: none" selected="main">{{currentCard.ageGroup}}</option>
                    <option value="OPEN">OPEN</option>
                    <option value="MASTERS">MASTERS</option>
                </select>
            </div>
        </div>
        <ImageUpload :image="currentCardImage" :parent-file-change="fileChange"/>
        <TextAreaEditor heading="Card Content: " :parent-method="changeCardContent" :value="currentCardContent"/>
        <button @click="saveCard" class="saveState btn btn-block btn-success">
            Save Card</button>
        <button @click="deleteCard" class="deleteButton btn btn-block btn-danger">
            Delete Card</button>
    </div>
</template>

<script>
    import CardService from "../../../services/CardService";
    import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
    import axios from "axios";
    import ImageUpload from "../../../assets/ImageUpload";
    import TextAreaEditor from "../../../assets/TextAreaEditor";
    import {backendURL} from '../../../environment'


    export default {
        name: "CardEditor",
        components: {TextAreaEditor, ImageUpload},
        data() {
            return {
                allCards: [],
                cards: [],
                status: "---",
                currentCard: {},
                currentCardTitle: "",
                currentCardContent: "",
                currentCardImage: "",
                currentCardType: "",
                eventGroup: ["MARATHON", "ROAD RUNNER", "XC", "TRACK", "FIELD"]

        }
        },
        computed: {
            timesIcon() {
                return faTimes
            }
        },
        methods: {
            fileChange(e) {
                if(window.confirm("Are you sure you want to upload this image?")) {
                    const allowedTypes = ["image/jpeg", "image,jpg", "image/png"];
                    var files = e.target.files || e.dataTransfer.files;
                    const file = files[0];
                    if (!allowedTypes.includes(file.type)) {
                        alert("Wrong Type!");
                        return;
                    }
                    if (file.size > 500000) {
                        alert("File too large!");
                        return;
                    }
                    let formData = new FormData();
                    formData.append('file', file);
                    axios.post(backendURL + 'api/cards/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(responso => {
                        this.currentCardImage = (responso.data.location);
                    }).catch(() => {
                        alert("Something went wrong uploading the image, please try again.")
                    })
                }
            },
            filterCards(event) {
                let temp = []
                this.allCards.forEach(card => {
                    if(event.target.value === card.type) {
                        temp.push(card)
                    }
                });
                if(temp.length > 0) {
                    this.cards = temp;
                    this.currentCard = temp[0];
                    this.currentCardTitle = temp[0].name;
                    this.currentCardContent = temp[0].content;
                    this.currentCardImage = temp[0].img;
                    this.currentCardType = temp[0].type;
                } else {
                    this.cards = [];
                    this.currentCard = {};
                    this.currentCardTitle = "";
                    this.currentCardContent = "";
                    this.currentCardImage = "";
                    this.currentCardType = "";
                }
            },
            changeCard(card) {
                this.currentCard = card;
                this.currentCardTitle = card.name;
                this.currentCardContent = card.content;
                this.currentCardImage = card.img;
                this.currentCardType = card.type
            },
            renderCards(cards) {
                this.allCards = cards;
                this.cards = cards
                this.currentCard = cards[0];
                this.currentCardTitle = cards[0].name;
                this.currentCardContent = cards[0].content;
                this.currentCardImage = cards[0].img;
                this.currentCardType = cards[0].type;
            },
            cardNameChange(event) {
                this.currentCardTitle = event.target.value
            },
            changeCardContent(event) {
                this.currentCardContent = event.target.value
            },
            removeEvent(event) {
                this.currentCard.eventGroup = this.currentCard.eventGroup
                    .filter(e => e !== event);
            },
            selectEvent(event) {
                this.currentEvent = event.target.value
            },
            addEvent() {
                let eventAdded = false;
                this.currentCard.eventGroup.forEach(e => {
                    if(this.currentEvent === e) {
                        eventAdded = true
                    }
                });
                if(eventAdded) {
                    alert("Event already added!")
                } else {
                    this.currentCard.eventGroup.push(this.currentEvent)
                }
            },
            changeImage(event) {
                this.currentCardImage = event.target.value
            },
            changeGender(event) {
                this.currentCard.gender = event.target.value
            },
            changeDivision(event) {
                this.currentCard.ageGroup = event.target.value
            },
            saveCard() {
                if(window.confirm("Are you sure you want to save element?")) {
                    let card = {
                        name: this.currentCardTitle,
                        type: this.currentCard.type,
                        content: this.currentCardContent,
                        img: this.currentCardImage,
                        author: this.currentCard.author,
                        eventGroup: this.currentCard.eventGroup,
                        gender: this.currentCard.gender,
                        ageGroup: this.currentCard.ageGroup,
                        userId: this.currentCard.userId

                    };
                    this.cardService.updateCard(this.$cookies.get('user'), this.currentCard._id, card).then(() => {
                        alert("Success");
                        this.cardService.findAllCards().then(cards => {
                            this.renderCards(cards)
                        })
                    }).catch(() => alert("Something went wrong please try again!"))
                }
            },
            deleteCard() {
                if(window.confirm("Are you sure you want to delete this element?")) {
                    this.cardService.deleteCard(this.$cookies.get('user'), this.currentCard._id).then(() => {
                        alert("Success!");
                        this.cardService.findAllCards().then(cards => {
                            this.renderCards(cards)
                        })
                    }).catch(() =>
                        alert("Something went wrong, try again.")
                    )
                }
            }
        },
        mounted() {
            this.cardService = CardService.getInstance();
            this.cardService.findAllCards().then(cards => {
                this.renderCards(cards)
            })
        },
    }
</script>

<style scoped>
    .cards {
        text-align: center;
    }
    .cardClear {
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        height: 14px;
        margin: auto;
        font-size: 14px;
        cursor: pointer;
        color: #ccc;
    }
    .groupOfCards {
        margin: 1%;
    }
    .inputBox {
        width: 80%;
    }
    .addDropdown {
        background-color: navy;
        color: white;
        margin: 1%;
        width: 90%;
    }
    .allCards {
        background-color: firebrick;
        margin-top: 2%;
        color: white;
        margin-right: 2%;
    }
    .allCards:hover {
        background-color: black;
    }

</style>
