<template>
    <div class="footerContainer">
        <div class="contactContainer">
            <ul>
                <b>Contact Us:</b>
                <li>
                    greaterbostontc@gmail.com
                </li>
            </ul>
        </div>
        <div class="donateContainer">
            <ul>
                <b>Ways to Donate:</b>
                <li>
                    <router-link class="donateLink" to="/donate">Donate on our site!</router-link>
                </li>
                <li>
                    <a href="https://smile.amazon.com/" class="amazonSmile">Amazon Smile</a>
                </li>
            </ul>
        </div>
        <div class="socialContainer">
            <a href="https://www.instagram.com/gbtcrunning/" target="_blank">
                    <font-awesome-icon class="instagramIcon" :icon="['fab', 'instagram']" size="2x" />
            </a>
            <a href="https://www.facebook.com/GBTCrunning/" target="_blank">
                <font-awesome-icon class="facebookIcon" :icon="['fab', 'facebook']" size="2x"/>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterBar"
    }
</script>

<style scoped>
    .donateLink {
        color: white;
    }
    .footerContainer {
        background: red;
        background-image: url('~@/assets/bostonMap.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-x: 20%;
        background-position-y: 80%;
        border-top: black solid 4px;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 180px;
        color: white;
        z-index: 50;
        font-size: 16px;
    }
    .amazonSmile {
        color: white;
    }
    @media (min-width: 450px) {
        .contactContainer {
            display: inline-flex;
            width: 50%;
        }
        .donateContainer{
            margin-top: 20px;
            display: inline-flex;
            width: 50%;
        }
    }

    @media (min-width: 1000px) {
        .footerContainer {
            font-size: 17px;
        }
    }
    .socialContainer {
        text-align: right;
        margin-right: 5%;
    }
    .facebookIcon {
        margin-left: 2%;
        color: white;
    }

    .instagramIcon {
        margin-left: 2%;
        color: white;
    }
</style>
