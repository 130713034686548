<template>
    <div class="form-check-inline input-box">
        <div class="form-heading">
            {{heading}} <span class="required-class" v-if="required"> * </span>
        </div>
        <div class="form-field">
            <input class="form-control" @change="parentMethod" :maxlength="maxLength" :type="type" :placeholder="placeHolder" :value="val"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StandardForm",
        props: {
            heading: String,
            placeHolder: String,
            type: String,
            val: String,
            required: Boolean,
            maxLength: Number,
            parentMethod: {type: Function}
        },
        data() {
            return {
                inputValue: "Alice",
            };
        },
    }
</script>

<style scoped>
    .input-box {
        width: 60%;
        margin-top: 1%;
    }
    .form-heading {
        color: dodgerblue;
        margin-right: 2%;
        width: 20%;
    }
    .form-field {
        width: 80%;
    }
    .required-class{
        font-size:16px;
        color: red;
    }

    @media (max-width: 700px) {
        .input-box {
            width: 90%;
            margin-top: 1%;
        }

        .form-field {
            width: 60%;
        }

        .form-heading {
            margin-right: 2%;
            width: 40%;
        }
    }
</style>
