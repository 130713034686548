<template>
    <div>
    <canvas class="bgCanvas fixed-top" />
    <nav class="navbar navbar-expand-md fixed-top">
        <router-link to="/">
            <img class="gbtcLogo" :src="gbtcLogo" alt="GBTC Icon"/>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="showDropdown = !showDropdown">
            <span class="navbar-toggler-icon hamburger" :class="hamburgerRotate"><font-awesome-icon class="bars" :icon="['fas', 'bars']" size="2x" /></span>
        </button>
        <div class="collapse navbar-collapse">
           <ul class="navbar-nav mr-auto nav">
               <li class="nav-item dropdown" v-for="nav in navItems" :key="nav.name">
                   <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       {{nav.name}}
                   </a>
                   <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                       <router-link v-for="dropdownItem in nav.additionalPages" :key="dropdownItem.name"
                                    :to="`/page/${dropdownItem.name}`" class="dropdown-item">{{dropdownItem.name}}</router-link>
                   </div>
               </li>
               <li class="nav-item">
                   <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       Events
                   </a>
                   <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                       <router-link v-for="event in events" :key="event.name"
                                    :to="`/event/${event.name}`" class="dropdown-item">{{event.name}}</router-link>
                   </div>
               </li>
               <li class="nav-item" v-if="loggedIn">
                   <router-link to="/members" class="nav-link">Members</router-link>
               </li>
                <li class="nav-item" v-if="!loggedIn">
                   <router-link to="/register" class="nav-link">Apply</router-link>
               </li>
               <li class="nav-item" v-if="!loggedIn">
                   <router-link to="/login" class="nav-link">Login</router-link>
               </li>
               <li class="nav-item" v-if="loggedIn" @click="logout">
                   <a class="nav-link">
                       Logout
                   </a>
               </li>
           </ul>
       </div>
        </nav>
        <div class="collapse dropoutMenu" id="navbarText">
            <ul class="navbar-nav mr-auto nav" id="mobileDropout">
                <li class="nav-item dropdown" v-for="nav in navItems" :key="nav.name">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{nav.name}}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" @click="showDropdown = !showDropdown">
                        <router-link data-toggle="collapse" data-target="#navbarText" v-for="dropdownItem in nav.additionalPages" :key="dropdownItem.name"
                                     :to="`/page/${dropdownItem.name}`" class="dropdown-item">{{dropdownItem.name}}</router-link>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Events
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" @click="showDropdown = !showDropdown">
                        <router-link data-toggle="collapse" data-target="#navbarText" v-for="event in events" :key="event.name"
                                     :to="`/event/${event.name}`" class="dropdown-item">{{event.name}}</router-link>
                    </div>
                </li>
                <li class="nav-item" v-if="loggedIn" data-toggle="collapse" data-target="#navbarText" @click="showDropdown = !showDropdown">
                    <router-link to="/members" class="nav-link">Members</router-link>
                </li>
                <li class="nav-item" v-if="!loggedIn" data-toggle="collapse" data-target="#navbarText" @click="showDropdown = !showDropdown">
                    <router-link to="/login" class="nav-link">Login</router-link>
                </li>
                <li class="nav-item" v-if="loggedIn" @click="logout">
                    <a class="nav-link">
                        Logout
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import gbtcLogo from "../assets/GBTClogoBACK .png"
    import {mapActions, mapGetters} from 'vuex'
    import EventRegistrationService from "../services/EventRegistrationService";
    export default {
        name: "NavBar",
        data(){
            return{
                navItems: [],
                gbtcLogo: gbtcLogo,
                log: true,
                events: [],
                showDropdown: false
            }
        },
        computed: {
          hamburgerRotate() {
            let hamburger = 'hamburgerRotate';
            if (this.showDropdown === true) {
              return hamburger;
            }
          return true;
          },
            ...mapGetters([
                'getNavItems',
                'loggedIn'
            ])
        },
        methods: {
            ...mapActions([
                'removeUser',
                'removeUserType'
            ]),
            logout() {
                this.removeUser();
                this.removeUserType;
                this.log = false
                this.$router.push('/')
            }
        },
        mounted() {
            this.getNavItems.then(navItems => {
                this.navItems = navItems
            })
            this.eventService = EventRegistrationService.getInstance()
            this.eventService.findAllEvents().then(events => {
                let tempEvents = [];
                events.forEach(e => {
                    if(e.status !== "DRAFT") {
                        tempEvents.push(e)
                    }
                });
                this.events = tempEvents
            })
        }
    }
</script>

<style scoped>

  #mobileDropout{
    margin-bottom: 7.5px;
    margin-left: -5px;
  }

  .dropoutMenu {
    background-color: red;
    position:fixed;
    width: 100%;
    top: 162px;
    z-index: 9999;
    -webkit-box-shadow: 0px 2px 14px 3px rgba(0,0,0,0.62);
    -moz-box-shadow: 0px 2px 14px 3px rgba(0,0,0,0.62);
    box-shadow: 0px 2px 14px 3px rgba(0,0,0,0.62);
  }

  .bgCanvas{
    background: no-repeat red url('~@/assets/bostonMap.svg');
    width: 100%;
    max-height: 162px;
    background-position-x: 20%;
    background-position-y: 64%;
  }
  @media(min-width: 800px) {
      .bgCanvas {
          background-size: 100%;
      }
  }

  .navbar {
      -webkit-box-shadow: 0px 2px 14px 3px rgba(0,0,0,0.62);
      -moz-box-shadow: 0px 2px 14px 3px rgba(0,0,0,0.62);
      box-shadow: 0px 2px 14px 3px rgba(0,0,0,0.62);
      border-radius: unset;
  }

  .navbar:after{
    content: unset;
  }

  .navbar:before{
    content: unset;
  }

  .nav-item a:hover{
    background: linear-gradient(to top, white, white 5px, transparent, transparent 0px)
  }

.dropdown-menu a:hover{
    background: unset;
}

.dropdown-item {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left:15px;
    padding-right:15px;
    font-size: 1.1em;
}

.dropdown-menu{
    padding-top:0;
    padding-bottom:0;
}


.dropdown-item:hover{
    background-blend-mode: color-dodge;
    background-color: #D90000 !important;
}

  .nav-link {
    font-size: 1.2em;
    font-weight: 400;
  }

  .nav-link:focus {
      color: black !important;
      background-color: white !important;
      border-radius: 10px;
  }

  .nav-link:hover {
      color: white;
  }

  .nav a {
      color: #fff;
  }

  .hamburger {
      font-size: 22px;
      color: #fff;
      transform: rotate(0deg);
      transition: 0.30s ease-in-out;
      transform-origin: 50% 50%;
  }

  .hamburgerRotate {
    transform: rotate(90deg);
    transition: 0.38s ease-in-out;
    transform-origin: 50% 50%;
  }

  .dropdown-menu {
      background-color: red;
  }

  .navbar-nav {
      width: 100%;
  }

  .nav-item {
      font-size: 15px;
      margin-left: 5%;
      cursor: pointer;
  }

  .gbtcLogo {
      height: 150px;
  }

  .gbtcLogo:hover{
    background: yellow transparent !important;

  }

  .navbar-toggler:focus, .navbar-toggler:active {
    outline: none;
    box-shadow: none;
  }

</style>
