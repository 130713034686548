<template>
    <div class="eventRegistrationContainer">
        <h1>{{eventTitle}} Registration</h1>
        <StandardForm heading="First Name" placeHolder="First Name" type="text" :val="registrant.firstName" :required="true" :parentMethod="changeFirstName"/>
        <StandardForm heading="Last Name" placeHolder="Last Name" type="text" :val="registrant.lastName" :required="true" :parentMethod="changeLastName"/>
        <StandardForm heading="Email" placeHolder="Email" type="text" :val="registrant.email" :required="true" :parentMethod="changeEmail"/>
        <StandardForm heading="Tee Shirt Size" placeHolder="Please enter" type="text" :val="registrant.teeShirtSize" :required="true" :parentMethod="changeTeeShirtSize"/>

        <StandardForm heading="DOB" placeHolder="10-27-1994" type="date" :val="registrant.dob" :required="true" :parentMethod="changeDOB"/>
        <StandardForm heading="Phone" placeHolder="1111111111" :maxLength="11" type="text" :val="registrant.phone" :required="true" :parentMethod="changePhone"/>
        <StandardForm heading="Country" placeHolder="" type="text" :val="registrant.country" :required="true" :parentMethod="changeCountry"/>
        <StandardForm heading="City" placeHolder="" type="text" :val="registrant.city" :required="true" :parentMethod="changeCity"/>
        <StandardForm heading="State" placeHolder="" :maxLength="2" type="text" :val="registrant.state" :required="true" :parentMethod="changeState"/>
        <StandardForm heading="Zip" placeHolder="" type="text" :maxLength="5" :val="registrant.zip" :required="true" :parentMethod="changeZip"/>
        <StandardForm heading="Emergency Contact" placeHolder="" type="text" :val="registrant.emergencyContact" :required="true" :parentMethod="changeContact"/>
        <StandardForm heading="Relationship" placeHolder="" type="text" :val="registrant.relationship" :required="true" :parentMethod="changeRelationship"/>
        <StandardForm heading="Their Phone Number" placeHolder="1111111111" :maxLength="11" type="text" :val="registrant.theirPhone" :required="true" :parentMethod="changeTheirPhone"/>,
        <StandardForm heading="USATF #" placeHolder="123456789" type="text" :val="registrant.usatfNumber" :required="false" :parentMethod="changeUSATF"/>
        <form >
            <label class="genderBox">
                <input type="radio" name="gender" value="male" @click="changeGender('male')">
            </label> Male<br>
            <label>
                <input type="radio" name="gender" value="female" @click="changeGender('female')">
            </label> Female<br>
            <label>
                <input type="radio" name="gender" value="other" @click="changeGender('other')">
            </label> Other
        </form>
        <div :key="registrant.event.length">
            <b-card v-for="race in events" :key="race.distance" :title="race.name" class="eventContainer">
                <b-card-text>Event Type: {{race.distance}}</b-card-text>
                <b-card-text>Time of Event: {{race.time}}</b-card-text>
                <b-card-text v-if="event.onePayment !== 'TRUE'">Price of Race: {{race.price}}$ </b-card-text>
                <b-button v-if="race.registered !== true" variant="primary" @click="signUp(race.price, race)">Sign me up!</b-button>
                <b-button v-else-if="race.registered === true" variant="danger" @click="deselect(race.price, race)">Nevermind!</b-button>
            </b-card>
        </div>
        <div :key="registrant.name + registrant.clothing.length">
            <b-card v-for="item in clothing" :key="item.name" :title="item.name" class="eventContainer">
                <b-card-img :src="item.img" height="200" width="100" top/>
                <b-card-text>Price: {{item.price}}</b-card-text>
                <b-button v-if="item.bought !== true" variant="primary" @click="buyItem(item.price, item)">I want it!</b-button>
                <b-button v-else-if="item.bought === true" variant="danger" @click="deselectItem(item.price, item)">Nevermind!</b-button>
            </b-card>
        </div>
        <h4 class="totalDue">
            Total Due: {{registrant.totalDue}}
        </h4>
        <h2 v-if="missingFields" class="missingFields">
            Missing Fields
        </h2>
        <h2 v-else-if="!missingFields" class="register">
            <router-link :to="`../payment/${eventTitle}`">Register!</router-link>
        </h2>
    </div>
</template>

<script>
    import StandardForm from "../../assets/StandardForm";
    import EventRegistrationService from "../../services/EventRegistrationService";
    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: "EventForm",
        components: {StandardForm},
        data() {
            return {
                registrant: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    division: "",
                    dob: "",
                    phone: "",
                    country: "",
                    zip: "",
                    city: "",
                    state: "",
                    emergencyContact: "",
                    relationship: "",
                    theirPhone: "",
                    usatfNumber: "",
                    team: "",
                    event: [],
                    clothing: [],
                    totalDue: 0,
                },
                clothing: [],
                events: {},
                eventTitle: "",
                missingFields: true
            }
        },
        computed: {
            ...mapGetters([
                'getRegistrant'
            ]),
        },
        methods: {
            ...mapActions([
                'editRegistrant'
            ]),
            update() {
                let payload = {
                    registrant: this.registrant
                };
                this.editRegistrant(payload);
            },
            buyItem(price, item) {
                this.registrant.totalDue += parseFloat(price);
                this.registrant.clothing.push(item);
                item.bought = true;
                this.checkStatus();
            },
            deselectItem(price, item) {
                this.registrant.totalDue -= parseFloat(price);
                const index = this.registrant.clothing.indexOf(item);
                if(index > -1) {
                    this.registrant.clothing.splice(index, 1);
                }
                item.bought = false;
                this.checkStatus();
            },
            signUp(price, race) {
                if(this.event.onePayment !== "TRUE") {
                    this.registrant.totalDue += parseFloat(price);
                }
                this.registrant.event.push(race);
                race.registered = true;
                this.checkStatus();
            },
            deselect(price, event) {
                if(this.event.onePayment !== "TRUE") {
                    this.registrant.totalDue -= parseFloat(price);
                }
                const index = this.registrant.event.indexOf(event);
                if(index > -1) {
                    this.registrant.event.splice(index, 1);
                }
                event.registered = false;
                this.checkStatus();
            },
            checkStatus() {
                if(this.registrant.firstName && this.registrant.lastName && this.registrant.email
                    && this.registrant.division && this.registrant.dob && this.registrant.phone
                    && this.registrant.country && this.registrant.zip && this.registrant.city && this.registrant.state
                    && this.registrant.emergencyContact && this.registrant.relationship
                    && this.registrant.theirPhone && this.registrant.event.length > 0) {
                    this.update();
                    this.missingFields = false
                } else {
                    this.missingFields = true
                }
            },
            changeFirstName(event) {
                this.registrant.firstName = event.target.value;
                this.checkStatus();
            },
            changeLastName(event) {
                this.registrant.lastName = event.target.value;
                this.checkStatus();
            },
            changeEmail(event) {
                this.registrant.email = event.target.value;
                this.checkStatus();
            },
            changeGender(event) {
                this.registrant.division = event;
                this.checkStatus();
            },
            changeDOB(event) {
                this.registrant.dob = event.target.value;
                this.checkStatus();
            },
            changePhone(event) {
                this.registrant.phone = event.target.value;
                this.checkStatus();
            },
            changeCountry(event) {
                this.registrant.country = event.target.value;
                this.checkStatus();
            },
            changeZip(event) {
                this.registrant.zip = event.target.value;
                this.checkStatus();
            },
            changeCity(event) {
                this.registrant.city = event.target.value;
                this.checkStatus();
            },
            changeState(event) {
                this.registrant.state = event.target.value;
                this.checkStatus();
            },
            changeContact(event) {
                this.registrant.emergencyContact = event.target.value;
                this.checkStatus();
            },
            changeRelationship(event) {
                this.registrant.relationship = event.target.value;
                this.checkStatus();
            },
            changeTheirPhone(event) {
                this.registrant.theirPhone = event.target.value;
                this.checkStatus();
            },
            changeUSATF(event) {
                this.registrant.usatfNumber = event.target.value;
                this.checkStatus();
            },
            changeTeam(event) {
                this.registrant.team = event.target.value;
                this.checkStatus();
            },
            changeEvent(event) {
                this.registrant.event = event.target.value;
                this.checkStatus();
            },
            changeTeeShirtSize(event) {
                this.registrant.teeShirtSize = event.target.value;
                this.checkStatus();
            }
        },
        mounted() {
            this.eventService = EventRegistrationService.getInstance();
            this.eventService.findEventByName(this.$route.params.name).then(event => {
                this.event = event;
                this.eventTitle = event.name;
                this.events = event.events;
                this.clothing = event.clothing;
                if(event.status !== "OPEN") {
                    this.$router.push("/")
                }
                if(Object.keys(this.getRegistrant).length !== 0) {
                    this.registrant = this.getRegistrant;
                    this.registrant.event = [];
                    this.registrant.clothing = [];
                    this.checkStatus();
                }
                if(event.onePayment === "TRUE") {
                    this.registrant.totalDue = event.onePaymentPrice;
                } else {
                    this.registrant.totalDue = 0
                }

            });
        }
    }
</script>

<style scoped>
    .eventRegistrationContainer {
        text-align: center;
    }
    .eventContainer{
        width: 40%;
        display: inline-flex;
        margin-top: 2%;
        margin-left: 2%;
        margin-right: auto;
    }
    .totalDue {
        margin-top: 15px;
    }
    .missingFields {
        margin-top: 15px;
        color: red;
    }
    .register {
        margin-top: 15px;
    }
</style>
