<template>
    <div class="cardsContainer">
        <h1 class="cardsHeader">Articles</h1>
        <div class="searchBarContainer">
            <input class="searchBar form-control" @change="searchArticles" type="text" placeholder="Search"
                   aria-label="Search"/>
            <font-awesome-icon @click="searchIconArticles" class="searchIcon" :icon="['fa', 'search']"/>
        </div>
        <div class="cardContentContainer">
            <Card class="card" v-for="article in (pages.slice((currentPage - 1) * perPage, currentPage * perPage))"
                  :key="article._id" :name="article.name" :author="article.author" :date="article.updatedAt"
                  :content="article.richText.slice(0, 250) + '...'" :image="article.img"/>
        </div>
        <div class="overflow-auto">
            <b-pagination class="paginate"
                          v-model="currentPage" pills
                          :total-rows="pages.length"
                          :per-page="perPage" size="lg" align="left"></b-pagination>
        </div>
    </div>
</template>

<script>
    import PageService from "../../services/PageService";
    import Card from "../../assets/Card";

    export default {
        name: "CardsComponent",
        components: {Card},
        data() {
            return {
                allPages: [],
                pages: [],
                searchString: "",
                currentPage: 1,
                perPage: 5
            }
        },
        methods: {
            sortArticles(articles) {
                if(articles) {
                    articles.sort((a, b) => {
                        let date1 = a.updatedAt;
                        let date2 = b.updatedAt;
                        let comparison = 0;
                        if (date1 < date2) {
                            comparison = 1;
                        } else if (date1 > date2) {
                            comparison = -1;
                        }
                        return comparison;
                    });

                }
                return articles
            },
            searchArticles(event) {
                this.searchString = event.target.value;
                this.pages = this.allPages.filter(page => page.name.toLowerCase().includes(this.searchString.toLowerCase()));
            },
            searchIconArticles() {
                this.pages = this.allPages.filter(page => page.name.toLowerCase().includes(this.searchString.toLowerCase()));
            }
        },
        computed: {

        },
        mounted() {
            this.pageService = PageService.getInstance()
            this.pageService.findAllPages().then(pages => {
                let articles = [];
                pages.forEach(page => {
                    if (page.type === 'ARTICLE' && page.status === 'PUBLISHED') {
                        articles.push(page)
                    }
                });

                this.pages = this.sortArticles(articles);
                this.allPages = this.sortArticles(articles);
            })
        }
    }
</script>

<style scoped>
    .paginate {
        width: 10%;
    }
    .cardsHeader {
        top: 50%;
        text-align: center;
        color: white;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2%;
        width: 100%;
        -webkit-text-stroke: 1px black;
        font-weight: bold;
        font-size: 60px;
        background: rgba(255,0,0, 0.9);
    }
    .cardContentContainer {
        margin-top: 3%;
    }
    .overflow-auto {
        width: 100%;
        margin-left: 2%;
    }
    .card {
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .cardsContainer {
        text-align: center;
    }
    .searchBarContainer {
        display: inline-flex;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2%;
    }
    .searchBar {
        width: 95%;
    }
    .searchIcon {
        width: 4%;
        margin-left: 1%;
        margin-top: 1%;
    }
    @media (max-width: 700px) {
        .instaPhoto {
            width: 70%;
        }
        .instaHover {
            width: 70%;
            left: 15%;
            font-size: 1.6rem;
        }
        .articleContainer {
            width: 70%;
        }
    }

    @media (max-width: 500px) {
        .instaPhoto {
            width: 80%;
        }
        .instaHover {
            width: 80%;
            left: 10%;
            font-size: 1.4rem;
        }
        .articleContainer {
            width: 80%;
        }
    }
</style>