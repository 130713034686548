<template>
    <div class="imageUploadContainer border border-dark">
        <h3 class="editorContentHeader">Image: </h3>
        <img class="imageUploadImage" :src="image"/>
        <input class="chooseImage btn btn-success" type="file" @change="parentFileChange">
    </div>
</template>

<script>
    export default {
        name: "ImageUpload",
        props: {
            image: String,
            parentFileChange: {type: Function},
            uploadImage: {type: Function},
        },
        data() {
            return {
                selectedFile: null
            }
        },
    }
</script>

<style scoped>
    .imageUploadContainer {
        margin-top: 2%;
        max-height: 500px;
    }
    .chooseImage {
        width: 50%;
        margin: 1%;
    }

    .imageUploadImage {
        width: 300px;
        height: 300px;
        margin-left: 1%;
        margin-top: 1%;
    }

    @media (max-width: 700px) {
        .imageUploadContainer {
            width: 90%;
        }
        .imageUploadImage {
            width: 200px;
            height: 200px;
            margin: 1%;
        }
    }

    @media (max-width: 350px) {
        .imageUploadImage {
            width: 150px;
            height: 150px;
            margin: 1%;
        }
    }

    @media (min-width: 1200px) {
        .chooseImage {
            width: 25%;
        }

        .uploadImage {
            width: 25%;
        }
    }
</style>