<template>
    <div class="article-body">
        <BannerImage :bannerTitle="articleTitle" :bannerImg="articleImg"/>
        <p class="articleBody" v-html="richText"></p>
    </div>
</template>

<script>
    import BannerImage from "../../assets/BannerImage";
    export default {
        name: "ArticleComponent",
        components: {BannerImage},
        props: ['articleTitle', 'richText', 'articleImg'],
        data() {
            return {
            }
        }
    }
</script>

<style scoped>
    .articleBody {
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        font-size: 18px;
        margin-top: 2%;
    }
    .bannerImage{
      object-fit: contain;
    }
</style>
