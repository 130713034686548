<template>
    <div class="passwordResetContainer">
        <h1 class="passwordResetTitle">Password Reset</h1>
        <div class="confirmPasswordContainer">
            <div class="inputContainer">
                <h3 class="inputHeading">Password</h3>
                <input class="form-control" type="password" :value="user.password" placeholder="password" @change="changePassword"/>
            </div>
            <div class="inputContainer">
                <h3 class="inputHeading">Confirm Password</h3>
                <input class="form-control" type="password" :value="confirmPassword" placeholder="password" @change="confirmPasswordChange"/>
            </div>
            <div class="buttonContainer">
                <button class="btn btn-block btn-success" @click="submitPassword">Submit New Password</button>
            </div>
        </div>
    </div>
</template>

<script>
    import MemberService from "../../services/memberService";

    export default {
        name: "PasswordReset",
        data() {
            return {
                user: {
                    password: "",
                },
                confirmPassword: ""
            }
        },
        methods: {
            changePassword(event) {
                this.user.password = event.target.value
            },
            confirmPasswordChange(event) {
                this.confirmPassword = event.target.value
            },
            submitPassword() {
                if(window.confirm("Are you sure you want to submit new Password?")) {
                    if(this.user.password === this.confirmPassword) {
                        if (this.user.password.length >= 5) {
                            this.memberService.changePassword(this.$route.params.token, this.user).then(() => {
                                alert("Success");
                                this.$router.push('/login')
                            }).catch(() => {
                                alert("Something went wrong, please try again")
                            })
                        } else {
                            alert("Password must be at least 5 characters long!")
                        }
                    } else {
                        alert("Passwords must match!")
                    }
                }
            }
        },
        mounted() {
            this.memberService = MemberService.getInstance();
            this.memberService.getResetToken(this.$route.params.token).then(() => {

            }).catch(() => {
                this.$router.push('/')
            })
        }
    }
</script>

<style scoped>
    .passwordResetContainer {
        text-align: center;
    }

    .passwordResetTitle {
        font-size: 25px;
        color: red;
        font-weight: bolder;
        font-style: italic;
    }
    .inputContainer {
        display: inline-block;
        width: 60%;
    }
    .inputHeading {
        display: inline-block;
        margin-top: 5%;
        width: 30%;
    }
    .form-control {
        display: inline-block;
        margin-left: 5%;
        width: 65%;
    }
    .buttonContainer {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5%;
    }

    @media (max-width: 700px)  {
        .inputContainer {
            width: 80%;
        }

    }
    @media (max-width: 600px)  {
        .inputContainer {
            width: 100%;
        }
        .inputHeading {
            font-size: 13px;
            width: 40%;
        }

        .form-control {
            display: inline-block;
            margin-left: 2%;
            margin-right: 2%;
            width: 56%;
        }

    }

</style>