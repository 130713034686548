import Vue from "vue";
import App from "./App.vue";
import routes from './routes'
import VueRouter from 'vue-router'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import store from './store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSort, faTrash, faArrowLeft, faArrowRight, faArrowUp, faImage,
  faArrowDown, faSortDown, faToggleOn, faToggleOff, faSortUp, faTimes, faBars, faBold, faItalic, faStrikethrough, faUnderline, faCode,
  faParagraph, faSearch, faList, faListOl} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import PayPal from 'vue-paypal-checkout'
import VueCookies from 'vue-cookies'
import excel from 'vue-excel-export'
import VueSwal from 'vue-swal'

Vue.config.productionTip = false;

library.add(faSort, faSortDown, faTrash, faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faArrowUp, faImage,
    faSortUp, faTimes, faToggleOn, faToggleOff, faBars, faBold, faItalic, faStrikethrough, faUnderline, faCode,
    faParagraph, faList, faSearch, faListOl);
library.add(faFacebook, faInstagram);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('paypal-checkout', PayPal);
const router = new VueRouter({mode: 'history', routes});
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(excel);
Vue.use(VueSwal);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
