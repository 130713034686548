<template>
    <div>
        <h1 id="navHeader" class="editorHeader">Nav Element Editor</h1>
        <div id="navs">
            <i v-for="nav in navs" :key="nav._id" @click="changeNavElement(nav)" class="navElements btn">
                {{nav.name}}
            </i>
        </div>
        <div id="name" class="editorContentContainer border border-dark">
            <h3 id="navElementHeader" class="editorContentHeader">Name of Nav Element</h3>
            <div id="changeName">
                <input class="inputBox form-control"
                       :value="this.navElementName"
                       @change="navElementNameChange"/>
            </div>
        </div>
        <div id="drop" class="editorContentContainer border border-dark">
            <h3 id="dropdownPagesHeader" class="editorContentHeader">Dropdown Pages</h3>
            <div id="groupOfPages" class="btn-group" v-for="page in navElementPages" :key="page._id">
                <input type="text" class="form-control" :value="page.name" readonly/>
                <span @click="deleteDropdown(page)" class="cardClear fa fa-times">
                    <font-awesome-icon   class="fa-times delete-icon" :icon="timesIcon" />
                </span>
            </div>
            <select @change="selectDropdownPage" id="navElementSelectDropdown" class="inputBox form-control">
                <option style="display: none" selected="main">---</option>
                <option v-for="page in allPages" :key="page._id" :value="page.name">{{page.name}}</option>
            </select>
            <button @click="addDropdown" class="addDropdown btn btn-block">Add Dropdown Page</button>
        </div>
        <button id="saveNavElement" @click="saveNavElement" class="saveState btn btn-block btn-success">
        Save Nav Element</button>
        <button id="deleteNavElement" @click="deleteNavElement" class="deleteButton btn btn-block btn-danger">
        Delete Nav Element</button>
    </div>
</template>

<script>
    import NavElementService from "../../../services/NavElementService";
    import PageService from "../../../services/PageService";
    import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';

    export default {
        name: "NavElementEditor",
        data() {
            return {
                navs: [],
                navElement: {},
                navElementName: "",
                additionalPages: [],
                allPages: [],
                navElementPages: [],
                dropdownPage: {}
            }
        },
        computed: {
            timesIcon(){
                return faTimes
            },
        },
        methods: {
            changeNavElement(element) {

                this.navElement = element;
                this.navElementName = element.name;
                this.navElementPages = element.additionalPages;
            },
            navElementNameChange(event) {
                this.navElementName = event.target.value
            },
            selectDropdownPage(event) {
                if (event !== "---") {
                    this.pageService.findPageByName(event.target.value).then(page =>
                        this.dropdownPage = page
                    );
                }
            },
            deleteDropdown(page) {
                for( let i = 0; i < this.navElementPages.length; i++){
                    if ( this.navElementPages[i]._id === page._id) {
                        this.navElementPages.splice(i, 1)
                    }
                }
            },
            isEmpty(obj) {
                for(var prop in obj) {
                    if(Object.prototype.hasOwnProperty.call(obj, prop)) {
                        return false;
                    }
                }
                return true;
            },
            addDropdown() {
                if(this.isEmpty(this.dropdownPage) === false) {
                    for(let i = 0; i < this.navElementPages.length; i++) {
                        if(this.navElementPages[i].name === this.dropdownPage.name) {
                            alert("Page already added");
                            return
                        }
                    }
                    let newPages = this.navElementPages;
                    newPages.push(this.dropdownPage);
                    this.navElementPages = newPages
                }
            },
            saveNavElement() {
                if(window.confirm("Are you sure you want to save element?")) {
                    let navElement = {
                        name: this.navElementName,
                        additionalPages: this.navElementPages,
                    };
                    this.navElementService.updateNavElement(this.$cookies.get('user'), this.navElement._id, navElement).then(() => {
                        alert("Success!");
                        this.navElementService.findAllNavElements().then(elements => {
                            this.navElements = elements;
                            this.navElement = elements[0];
                            this.navElementPages = elements[0].additionalPages;
                            this.navElementName = elements[0].name
                        })
                    }).catch(() => alert("Something went wrong, please try again!"))
                }
            },
            deleteNavElement()  {
                if(window.confirm("Are you sure you want to delete this element?")) {
                    this.navElementService.deleteNavElement(this.$cookies.get('user'), this.navElement._id).then(() => {
                        alert("Success!");
                        this.navElementService.findAllNavElements().then(elements => {
                            this.navElements = elements;
                            this.navElement = elements[0];
                            this.navElementPages = elements[0].additionalPages;
                            this.navElementName = elements[0].name;
                        })
                    }).catch(() => {
                        alert("Something went wrong, please try again")
                    })
                }
            }

    },
        mounted() {
            this.navElementService = NavElementService.getInstance();
            this.navElementService.findAllNavElements().then(navs => {
                this.navs = navs;
                this.navElement = navs[0];
                this.navElementName = navs[0].name;
                this.navElementPages = navs[0].additionalPages;

            });
            this.pageService = PageService.getInstance();
            this.pageService.findAllPages().then(pages => {
                let tempPages = [];
                pages.map(page => {
                    if (page.status === "PUBLISHED") {
                        tempPages.push(page)
                    }
                });
                this.allPages = tempPages
            })
        }
    }
</script>

<style scoped>
    .inputBox {
        width: 90%;
    }
    #navs {
        text-align: center;
    }

    .addDropdown {
        background-color: navy;
        color: white;
        margin: 1%;
        width: 90%;
    }
    .navElements {
        background-color: #BC243C;
        margin-top: 2%;
        color: white;
        margin-right: 2%;
    }

    .cardClear {
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        height: 14px;
        margin: auto;
        font-size: 14px;
        cursor: pointer;
        color: #ccc;
    }

    #navElementAdditionalPages {
        width: 150px;
    }

    #navElementClear {
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        height: 14px;
        margin: auto;
        font-size: 14px;
        cursor: pointer;
        color: #ccc;
    }

    #navElementAdditionalPages, #groupOfPages{
        margin: 1%;
    }


</style>