import {backendURL} from '../environment'

let API_URL = backendURL + 'api/';

export default class CardService {
    static myInstance = null;

    static getInstance() {
        if (CardService.myInstance == null) {
            CardService.myInstance = new CardService();
        }
        return this.myInstance
    }

    findAllCards = () =>
        fetch(API_URL + "cards", {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

    findCardByName = (cardName) =>
        fetch(API_URL + "cards/name/" + cardName, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

    findCardById = (id) =>
        fetch(API_URL + 'cards' + id)
            .then(response => response.json());

    createCard = (token, card) => {
        return fetch(API_URL + "cards", {
            method: 'post',
            body: JSON.stringify(card),
            headers: {
                'content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json())
            .catch(() => alert("Card Could Not Be Created"));
    };

    updateCard = (token, cardId, card) => {
        return fetch(API_URL + "cards/" + cardId, {
            method: 'put',
            body: JSON.stringify(card),
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };

    deleteCard = (token, cardId) => {
        return fetch(API_URL + "cards/" + cardId, {
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }).then(response =>
            response.json());
    };


}
