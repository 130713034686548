import {backendURL} from '../environment'

let API_URL = backendURL + 'api/';

export default class DonationService {
    static myInstance = null;

    static getInstance() {
        if (DonationService.myInstance == null) {
            DonationService.myInstance = new DonationService();
        }
        return this.myInstance
    }

    findAllDonations = () =>
        fetch(API_URL + "donation", {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            response.json());

    createDonation = (donation) =>
        fetch(API_URL + "donation", {
            method: 'post',
            body: JSON.stringify(donation),
            headers: {
                'content-Type': 'application/json'
            }
        }).then(response =>
            response.json())
            .catch(() => alert("Donation Could Not Be Created"));


}