<template>
    <div>
        <h1 id="navHeader" class="editorHeader">Home Page Editor</h1>
        <div id="drop" class="editorContentContainer border border-dark">
            <h3 id="dropdownPagesHeader" class="editorContentHeader">Choose Featured Page</h3>
            <select @change="selectDropdownPage" id="navElementSelectDropdown" class="inputBox form-control">
                <option style="display: none" selected="main">{{featuredPage.name}}</option>
                <option v-for="page in allPages" :key="page._id" :value="page._id">{{page.name}}</option>
            </select>
        </div>
        <button @click="savePage" class="addNav btn btn-block btn-success">Save Home Page</button>
    </div>
</template>

<script>
    import PageService from "../../../services/PageService";

    export default {
        name: "HomePageEditor",
        data() {
            return {
                featuredPage: {},
                allPages: [],
                homePage: {}
            }
        },
        methods: {
            selectDropdownPage(event) {
                this.featuredPage = event.target.value
            },
            savePage() {
                if (window.confirm("Are you sure you want to save this element?")) {
                    this.homePage.featuredPage = this.featuredPage;
                    this.pageService.updatePage(this.$cookies.get('user'), this.homePage._id, this.homePage).then(() => {
                        alert("Success!")
                    }).catch(() => alert("Something went wrong, please try again"))
                }
            }
        },
        mounted() {
            this.pageService = PageService.getInstance();
            this.pageService.findAllPages().then(pages => {
                this.allPages = pages
            });
            this.pageService.findPageByName('HomePage').then(page => {
                this.featuredPage = page.featuredPage
                this.homePage = page
            });
        }
    }
</script>

<style scoped>
    .inputBox {
        width: 80%;
    }
    .addNav {
        margin-top: 2%;
    }
</style>