<template>
        <div class="container-fluid">
            <div class="sidenav">
                <router-link v-if="userType === 'ADMIN'" to="/members/navElementEditor">
                    <button class="btn btn-block btn-dark editorPill">NavElement Editor</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/pageEditor">
                    <button class="btn btn-block btn-dark editorPill">Page Editor</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/cardEditor">
                    <button class="btn btn-block btn-dark editorPill">Card Editor</button>
                </router-link>
                <router-link class="editorPill" v-if="userType === 'ADMIN'" to="/members/eventEditor">
                    <button class="editorPill btn btn-block btn-dark">Event Editor</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/newNavElementEditor">
                    <button class="btn btn-block btn-primary newPill">New Nav Element</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/newPageEditor">
                    <button class="btn btn-block btn-primary newPill">New Page</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/newCardEditor">
                    <button class="btn btn-block btn-primary newPill">New Card</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/newEventEditor">
                    <button class="btn btn-block btn-primary newPill">New Event</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/homePageEditor">
                    <button class="btn btn-block btn-dark newPill">Home Page Editor</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/newMembers">
                    <button class="btn btn-block btn-success newPill">New Member Management</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/members">
                    <button class="btn btn-block btn-success newPill">Member Management</button>
                </router-link>
                <router-link v-if="userType === 'ADMIN'" to="/members/donations">
                    <button class="btn btn-block btn-success newPill">View Donations</button>
                </router-link>
                <router-link v-if="loggedIn" to="/members/profile">
                    <button class="btn btn-block btn-dark newPill">Profile Management</button>
                </router-link>
<!--                <router-link v-if="userType !== 'NONMEMBER'" to="/members/gbtcStore">-->
<!--                    <button class="btn btn-block btn-dark newPill">GBTC Store</button>-->
<!--                </router-link>-->
                <router-link v-if="userType !== 'NONMEMBER'" to="/members/gbtcRaceReg">
                    <button class="btn btn-block btn-dark newPill">GBTC Race Registration</button>
                </router-link>
            </div>
            <div class="editorBody">
                <router-view/>
            </div>
        </div>
</template>

<script>
    import Vue from "vue";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "EditorComponent",
        data() {
            return {
                type: ""
            }
        },
        computed: {
            ...mapGetters([
                'userType', 'loggedIn'
            ])
        },
        methods: {
            ...mapActions([
                'patchUserType'
            ]),
        },
        mounted() {
            let payload = {
                token: Vue.$cookies.get('user')
            };
            this.patchUserType(payload);
            if(Vue.$cookies.get('user') === 'ERROR' || !Vue.$cookies.get('user')) {
                this.$router.push('/')
            }
        }
    }
</script>

<style>
    /* The sidebar menu */
    .sidenav {
        height: 100%;
        border-top: black;
        border-top-style: solid;
        border-top-width: 5px;
        width: 160px;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        margin-top: 150px;
        background-color: red;
        overflow-x: hidden;
    }
    @media (max-width: 750px) {
        .sidenav  {
            width: 100px;
        }
    }

    .editorBody {
        margin-left: 160px;
    }
    @media (max-width: 750px) {
        .editorBody  {
            margin-left: 100px;
        }
    }

    .editorHeader{
        color: red;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        font-weight: 900;
        font-size: 3.5em;
        text-align: center;
        margin-bottom: 20px;
        padding-top: 1px;
    }

    /* The navigation menu links */
    #sideNavItems {
        padding: 6px 8px 6px 16px;
        text-decoration: none;
        font-size: 25px;
        color: white;
        display: block;
        border-bottom: 5px solid white;

    }

    /* When you mouse over the navigation links, change their color */
    .sidenav a:hover {
        color: #f1f1f1;
    }

    /* Style page content */
    .main {
        margin-left: 160px; /* Same as the width of the sidebar */
        padding: 0px 10px;
    }

    /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
    @media screen and (max-height: 450px) {
        .sidenav {padding-top: 15px;}
        .sidenav a {font-size: 18px;}
    }

    .editorPill {
        position: center;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .richTextEditorContainer {
        margin: 1%;
    }
    .newPill {
        position: center;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .editorContentContainer {
        margin-top: 2%
    }

    .editorContentHeader {
        margin: 1%;
    }

    .inputBox {
        margin: 1%;
        width: 80%;
    }

    .saveState {
        margin-top: 2%;
    }

    .deleteButton {
        margin-bottom: 2%;
    }

    .createButton {
        margin-top: 2%;
        margin-bottom: 2%;
    }


</style>
