<template>
    <div class="donateContainer">
        <h1 class="donateHeader">Donate</h1>
        <StandardForm type="string" heading="Name: " place-holder="Bill Rodgers" :val="donation.name" :parent-method="changeName"/>
        <StandardForm type="string" heading="Email: " place-holder="billRodgers@gbtc.org" :val="donation.email" :parent-method="changeEmail"/>
        <StandardForm type="number" heading="Phone (optional): " place-holder="123456789" :val="donation.phone" :parent-method="changePhone"/>
        <StandardForm type="string" heading="City (optional): " place-holder="Boston" :val="donation.city" :parent-method="changeCity"/>
        <StandardForm type="string" heading="State (optional): " place-holder="MA" :max-length="2" :val="donation.state" :parent-method="changeState"/>
        <StandardForm type="string" heading="Message: " place-holder="" :val="donation.message" :parent-method="changeMessage"/>
        <standard-form type="number" heading="Amount To Donate: " place-holder="1230" :val="donation.total" :parent-method="changeTotal"/>
        <div v-if="isMount" class="payContainer">
            <Payment :authorized="createDonation" :cancelled="cancelled" :payment="donation.total.toString()" :credentials="client" />
        </div>
    </div>
</template>

<script>
    import DonationService from '../../services/DonationService'
    import StandardForm from "../../assets/StandardForm";
    import Payment from "../../assets/Payment";
    import ApiService from "../../services/apiService";

    export default {
        name: "DonateComponent",
        components: {Payment, StandardForm},

        data() {
            return {
                client : {},
                isMount: false,
                modalShow: false,
                donation: {
                    name: "",
                    email: "",
                    total: "",
                    phone: "",
                    city: "",
                    state: "",
                    message: "",
                }
            }
        },
        methods: {
            changeName(e) {
                this.donation.name = e.target.value
            },
            changeEmail(e) {
                this.donation.email = e.target.value
            },
            changeTotal(e) {
                this.donation.total = e.target.value
            },
            changePhone(e) {
                this.donation.phone = e.target.value
            },
            changeCity(e) {
                this.donation.city = e.target.value
            },
            changeState(e) {
                this.donation.state = e.target.value
            },
            changeMessage(e) {
                this.donation.message = e.target.value
            },
            createDonation() {
                this.donationService.createDonation(this.donation).then(() => {
                    this.$swal({
                        title: "Thank you for your generous donation!",
                        text: "We appreciate your donation greatly!",
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    });
                    this.donation = {
                        name: "",
                        email: "",
                        total: "",
                        phone: "",
                        city: "",
                        state: "",
                        message: ""
                    }
                }).catch(() => {
                    alert("Something went wrong, please try again!")
                })

            },
            cancelled() {
                alert("Transaction Cancelled!")
            }
        },
        mounted() {
            this.donationService = DonationService.getInstance();
            let apiService = ApiService.getInstance();
            apiService.findPPKey().then(ppKey => {
                this.client = ppKey;
                this.isMount = true;
            });
        }
    }
</script>

<style scoped>
    .donateContainer {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .donateHeader {
        top: 50%;
        text-align: center;
        color: white;
        border-radius: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2%;
        width: 100%;
        -webkit-text-stroke: 1px black;
        font-weight: bold;
        font-size: 60px;
        background: rgba(255,0,0, 0.9);
    }
    .payContainer {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5%;
    }

</style>
